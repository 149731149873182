<template>
  <div id="portals-access" :style="{ width: portalWidth }">
    <!-- header -->

    <div class="row portal-header">
      <div v-if="isLogoRequired" class="col-auto logo q-pa-md">
        <img :src="_logo" :height="logoHeight" />
      </div>
      <div class="header col">
        <div class="col text-center">
          <div class="title-1">{{ title }}</div>
          <!-- <div class="description">{{ description }}</div> -->
        </div>

        <ThemeSwitcher class="q-ml-sm" :no-border="false" />

        <BaseActionButton
          is-flat
          icon="eva-power-outline"
          no-border
          class="q-ml-sm"
          @click="logOut"
        />
      </div>
    </div>

    <!-- ... -->

    <Sheet
      v-model="processSheet"
      width="100vw"
      has-footer
      no-padding
      @input="processSheet = false"
    >
      <!-- title -->

      <template #title>{{ headerTitle }} </template>

      <!-- ... -->

      <template #actions>
        <template v-if="workflow.initiatedBy !== 'DOCUMENT'">
          <template v-if="mandatory.filledValue || mandatory.nonFilledValue">
            <q-circular-progress
              show-value
              font-size="12px"
              :value="mandatoryFilling"
              size="lg"
              :thickness="0.22"
              color="primary"
              track-color="grey-3"
              class="q-mr-sm"
            >
              {{ mandatoryFilling }}%
            </q-circular-progress>
          </template>
          <div v-if="mandatory.filledValue" class="chip-success q-mr-sm">
            <div class="">{{ mandatory.filledValue }} ready</div>
          </div>
          <div
            v-if="mandatory.nonFilledValue"
            class="chip-warning"
            @click="checkFormValidation"
          >
            <div class="">{{ mandatory.nonFilledValue }} need to work</div>
          </div>
        </template>
        <template v-if="requiredDocuments.length">
          <div
            class="chip-warning cursor-pointer"
            @click="showAttachmentSheet = true"
          >
            <div class="">
              {{ requiredDocuments.length }} document(s) required
            </div>
          </div>
        </template>
        <BaseActionButton
          v-if="
            !formView &&
            Object.keys(selectedProcess).length &&
            actions.length === 0
          "
          v-tooltip.top="'Form View'"
          is-flat
          :icon="'mdi-form-select'"
          class="q-mr-sm"
          @click="formView = true"
        />

        <BaseActionButton
          v-else-if="
            Object.keys(selectedProcess).length && actions.length === 0
          "
          v-tooltip.top="'Details View'"
          is-flat
          :icon="'mdi-table-column'"
          class="q-mr-sm"
          @click="formView = false"
        />
      </template>

      <!-- content -->

      <div id="content-wrapper" class="row">
        <div v-if="formId" id="form-view" class="col">
          <BaseScrollbar height="calc(100vh - 145px)">
            <RenderForm
              :form-id="formId"
              :panels="panels"
              :secondary-panels="secondaryPanels"
              :form-settings="formSettings"
              :form-model="formModel"
              :selected-process="selectedProcess"
              :workflow-actions="[]"
              :save-action.sync="saveAction"
              :workflow-form-model.sync="workflowFormModel"
              :workflow-model.sync="workflowModel"
              :is-readonly="formEditAccess"
              :form-edit-controls="formEditControls"
              :readonly-controls="readonlyControls"
              :form-visibility-access="formVisibilityAccess"
              :form-secure-controls="formSecureControls"
              :enable-controls="_enableControls"
              :form-upload-files="formUploadFiles"
              :has-check-form-validate.sync="hasCheckFormValidate"
              :ocr-result="ocrResult"
              :check-list.sync="checkList"
              :has-change-table-field="hasChangeTableField"
              @uploadFiles="getOCRResult"
              @mandatoryControlsList="mandatoryControlsList"
              @deletedFiles="deletedFiles"
              @showQr="generateQrCode"
              @uploadFilesInTable="getOCRResultInTable"
              @deletedFilesInTable="deletedFilesInTable"
              @viewFile="viewFile"
            />
          </BaseScrollbar>
        </div>
        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
      </div>

      <!-- ... -->

      <!-- footer -->
      <template v-if="actions.length" #footer>
        <!-- <q-space /> -->
        <BaseButton
          label="Save"
          class="q-ml-md"
          color="secondary"
          @click="
            {
              btnAction = true;
              saveAction = 'Save';
            }
          "
        />
        <template v-for="action in actions">
          <BaseButton
            :key="action"
            :label="action"
            class="q-ml-md col-auto"
            @click="
              {
                btnAction = true;
                saveAction = action;
              }
            "
          />
        </template>
      </template>
      <!-- ... -->
    </Sheet>

    <!-- content -->
    <div class="content">
      <div class="q-pa-sm">
        <div v-if="workflowId && trackRequest">
          <BaseScrollbar height="calc(100vh - 160px)'">
            <div class="row q-mb-sm">
              <div class="toggle-btn justify-center row items-center q-pr-md">
                <div
                  class="col-auto q-mr-sm q-ml-sm q-pl-sm q-pr-sm"
                  :class="[
                    inbox ? 'active-btn' : '',
                    darkTheme ? 'custom-tab' : '',
                  ]"
                  @click="getlist(true)"
                >
                  Application Received
                  <span v-if="inboxCount" class="data-count">{{
                    inboxCount
                  }}</span>
                </div>
                <div
                  class="col q-mr-sm q-ml-md q-pl-sm q-pr-sm"
                  :class="[
                    !inbox ? 'active-btn' : '',
                    darkTheme ? 'custom-tab' : '',
                  ]"
                  @click="getlist(false)"
                >
                  All Applications
                  <span v-if="processCount" class="data-count">
                    {{ processCount }}
                  </span>
                </div>
              </div>

              <q-space />
              <div class="col-auto q-mr-sm">
                <BaseButton
                  v-if="initiate"
                  label="Submit your request"
                  @click="openNewRequest"
                />
              </div>
              <div class="col-auto q-mt-xs">
                <div id="global-search">
                  <BaseIcon name="eva-search" />

                  <div class="label">
                    <input
                      ref="searchQuery"
                      v-model="search"
                      type="text"
                      class="q-px-xs"
                      placeholder="Search request..."
                      @keyup="searchList()"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto q-ml-xs">
                <PortalFilterItems
                  item="proces"
                  :columns="_columns"
                  :filter-by.sync="filterBy"
                  :selected-filter="selectedFilter"
                  @update="getWorkflowData"
                  @refresh="getRequestList"
                />
              </div>
              <div class="col-auto q-ml-xs">
                <BaseActionButton
                  v-tooltip.top="'Refresh'"
                  icon="eva-refresh"
                  color="gray"
                  no-border
                  is-flat
                  size="20px"
                  @click="getRequestList"
                />
              </div>
              <div class="col-auto q-ml-xs q-mr-md">
                <BaseActionButton
                  v-tooltip.top="'Export'"
                  is-flat
                  no-border
                  icon="mdi-table-arrow-right"
                  @click="export_Data"
                />
              </div>
            </div>
            <div class="subdetcontent">
              <BaseLoadingBarSheet
                v-if="loadingBarContent"
                class="loading-content"
              />
              <BaseScrollbar height="calc(100vh - 180px)">
                <PortalList
                  :items="_items"
                  :columns="_columns"
                  :form="form"
                  :list="workflowRequestList"
                  :process-level="processLevel"
                  :form-view.sync="formView"
                  :workflow="workflow"
                  @actions="showLinks"
                  @subactions="showSubLinks"
                  @open="openProcess"
                  @opensubprocess="openSubProcess"
                  @viewFile="openFile"
                  @update:formView="handleFormViewUpdate"
                />
              </BaseScrollbar>
            </div>
            <Pagination
              v-if="false"
              class="portal"
              :total-items="totalItems"
              :current-page.sync="currentPage"
              :items-per-page.sync="itemsPerPage"
            />
          </BaseScrollbar>
        </div>
      </div>
    </div>

    <!-- ... -->

    <!-- attachments -->

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="checkListTemplate"
      :sub-actions-height="105"
      :upload="uploadAccess"
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 15 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          class="q-mr-xs"
          no-border
          is-flat
          size="20px"
          @click="getAttachments(0)"
        />
      </template>

      <template v-if="uploadAccess" #upload>
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template #subActions>
        <div class="q-mt-sm">
          <div class="row q-mb-sm">
            <div class="q-mt-xs">Checklist Documents</div>
            <q-space />
            <div @click="downloadAll">
              <q-chip outline color="grey" class="chips grey-hover large">
                Download All
              </q-chip>
            </div>
          </div>
          <div class="row q-mb-md description text-sm text-grey">
            It can be useful to ensure that all necessary information and
            supporting documents are included when submitting a claim
          </div>
        </div>
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 210px)">
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template
              v-if="row.name && (!row.hasOwnProperty('visible') || row.show)"
            >
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <div :key="file.id" class="attachmentList">
              <div
                class="title row"
                :class="checkTenant ? 'cursor-pointer' : ''"
              >
                <div v-if="selectedProcess.processId" class="col-auto">
                  <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                  <BaseIcon
                    :name="
                      file.checked
                        ? 'eva-checkmark-circle-2'
                        : 'eva-radio-button-off-outline'
                    "
                    color="secondary"
                    class="q-mr-sm"
                    @click="file.checked = !file.checked"
                  />
                </div>
                <!-- <div v-if="file.formJsonId" class="col-auto">
                  <BaseIcon
                    v-tooltip:green.top="getLabel(file.formJsonId)"
                    name="mdi-form-select"
                    color="primary"
                    class="q-mr-sm"
                  />
                </div> -->
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openFile(file)">
                  {{ file.name }}
                </div>

                <div v-if="checkTenant" class="col-auto">
                  <BaseActionButton
                    v-tooltip.top="'delete'"
                    icon="eva-trash-2-outline"
                    color="red"
                    no-border
                    is-flat
                    size="20px"
                    @click.stop="attachmentDelete(file)"
                  />
                </div>
              </div>

              <div v-if="file.formJsonId" class="row q-mb-sm">
                <div class="col-auto ellipsis attachment-label">
                  {{ getLabel(file.formJsonId) }}
                </div>
              </div>

              <div class="meta row">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- created by -->

                <template v-if="file.createdBy">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ file.createdByEmail }}</div>
                </template>

                <!-- ... -->

                <!-- size -->

                <!-- <template v-if="file.size">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ fileSize(file.size) }}</div>
              </template> -->

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>

        <div class="q-mt-xs defaultBottom">
          <BaseIcon name="mdi-circle-medium" color="red" /> Required Documents
        </div>
      </div>
    </Sheet>

    <!-- ... -->

    <!-- comments -->
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 155px)">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-2'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div class="col q-pa-sm borderTop">
        <div class="row">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <!-- ... -->

    <!-- file -->

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <template v-if="selectedFile.createdAt">
                <div>
                  Archived At: {{ $day.format(selectedFile.createdAt) }}
                </div>
                <BaseIcon name="mdi-circle-small" />
              </template>
              <template v-if="selectedFile.createdByEmail">
                <div>
                  Archived By:
                  {{ selectedFile.createdByEmail || selectedFile.createdBy }}
                </div>
              </template>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <!-- ... -->

    <Confirm
      v-model="successModalClaim"
      icon="eva-checkmark-circle-2"
      icon-color="green"
      size="24px"
      @input="
        {
          successModalClaim = false;
          refNo = '';
        }
      "
    >
      <template #title>Claim Registered!</template>

      <template #default>
        <div class="q-py-md text-lg">
          <p>
            Thank for submitting your Involuntary Unemployment claim to PSG.
          </p>
          <p>
            Your reference number is<span class="text-primary">
              "<b>{{ refNo }}"</b>.
            </span>
          </p>
          <p class="text-secondary">
            We have sent a link to the email address you provided which allows
            you to track your claim.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="primary"
          @click="
            {
              successModalClaim = false;
              refNo = '';
              processSheet = false;
            }
          "
        />
      </template>
    </Confirm>

    <Confirm
      v-model="successModal"
      icon="eva-checkmark-circle-2"
      icon-color="green"
      size="24px"
      @input="
        {
          successModal = false;
          refNo = '';
        }
      "
    >
      <template #title>Request Registered!</template>

      <template #default>
        <div class="q-py-md text-lg">
          <p>Thank for submitting your Request.</p>
          <p>
            Your reference number is<span class="text-primary">
              "<b>{{ refNo }}"</b>.
            </span>
          </p>
          <p class="text-secondary">
            We have sent a link to the email address you provided which allows
            you to track your request.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="primary"
          @click="
            {
              successModal = false;
              refNo = '';
              processSheet = false;
            }
          "
        />
      </template>
    </Confirm>

    <Confirm
      v-model="documentAlertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="closeDocumentAlertModal"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the claim registration without
            attaching the required documents.
          </p>

          <p class="text-secondary">
            After registration, you will get the link to track your claim.<br />From
            there, you can upload the required documents once you have them.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="closeDocumentAlertModal"
        />
        <BaseButton label="yes" color="primary" @click="initiateRequest" />
      </template>
    </Confirm>

    <Confirm
      v-model="alertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="closeAlertModal"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the request without attaching the
            required documents.
          </p>

          <p class="text-secondary">
            After registration, you will get the link to track your request.<br />From
            there, you can upload the required documents once you have them.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="closeAlertModal"
        />
        <BaseButton label="yes" color="primary" @click="initiateRequest" />
      </template>
    </Confirm>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <MetaDataEdit
      v-if="showMetaDataEditModal"
      :value="showMetaDataEditModal"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      title="Index"
      :columns="_columns"
      :modal-value="workflowModel"
      :upload-filename="uploadFilename"
      @close="closeMetaDataEditModal"
      @save="saveMetaData"
    />

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <SubWorkflowProcess
      v-if="openSubProcessSheet"
      :workflow-id="subWorkflowId || subProcessData.id"
      :main-workflow-id="workflow.id"
      :main-process-id="selectedProcess.processId"
      :selected-process="subProcessData"
      :open-sub-process="openSubProcessSheet"
      :portal="true"
      @closeProcessSheet="closeSubProcess"
    />
    <GenerateQr
      :value="showQr"
      :repository-id="workflow.repositoryId"
      :field-id="selectedFieldId"
      :field-name="selectedFieldName"
      :is-file-uploaded="QRFileUploaded"
      @checkUploadId="checkMobileUpload"
      @expired="QRexpired"
      @input="showQr = false"
    />

    <Attachments
      v-if="showAttachmentSheet"
      :value.sync="showAttachmentSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="selectedNode"
      :initiate-process="initiate"
      :form-check-list="false"
      :check-list="checkList"
      :wform="form"
      :repository-details="repositoryDetails"
      :workflow-model="workflowModel"
      :form-upload-files="formUploadFiles"
      :repository-field="repositoryField"
      :columns="_columns"
      :attachments.sync="attachmentList"
      :workflow-id="workflow.id"
      :is-portal="true"
      @attachmentCount="attachmentCount"
    />

    <Comments
      v-if="showCommentsSheet"
      :value.sync="showCommentsSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="selectedNode"
      :initiate-process="initiate"
      :attachment-list="attachmentList"
      :workflow-id="workflow.id"
      :file-names="[]"
      :comments.sync="commentsList"
      :display-internal-public="false"
      :is-portal="true"
      @commentsCount="commentsCount"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import formatBytes from "@/helpers/format-bytes.js";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
  fileSupport,
} from "@/helpers/file-format.js";
import axios from "axios";
import FileIcon from "@/components/common/FileIcon.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import {
  portal,
  workflow,
  form,
  repository,
  uploadAndIndex,
  report,
  user,
  menu,
} from "@/api/factory.js";
import Confirm from "@/components/common/popup/Confirm.vue";
import PortalFilterItems from "./components/PortalFilterItems.vue";
import PortalList from "./components/PortalList.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import { exportData } from "@/helpers/export-excel-data";
import ListItem from "@/components/common/ListItem.vue";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import SubWorkflowProcess from "@/views/workflows/views/workflows-inbox/components/components/SubWorkflow";
import { fileToBase64 } from "@/helpers/file-to-base64.js";
import { decrypt } from "@/helpers/crypto";
import { lowerCase, isEmpty } from "lodash-es";
import Attachments from "@/components/common/workflow/Attachments.vue";
import Comments from "@/components/common/workflow/Comments.vue";
import DataType from "@/constants/data-type.js";
import GenerateQr from "@/components/common/form/file-upload-field/GenerateQr.vue";

export default {
  name: "PortalsAccess",
  components: {
    ThemeSwitcher,
    RenderForm,
    Sheet,
    FileIcon,
    TextAreaField,
    Modal,
    Confirm,
    PortalFilterItems,
    PortalList,
    TextBuilderField,
    Pagination,
    ListItem,
    MetaDataEdit,
    ProcessHistory,
    SubWorkflowProcess,
    Attachments,
    Comments,
    GenerateQr,
  },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    portalId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      inbox: true,
      loadingBarContent: false,
      portalWidth: "100%",
      breadcrumbs: [],
      activeItem: "",
      tabList: [
        {
          id: this.$nano.id(),
          label: "Submit a claim",
          size: "12",
          description:
            "If you wish to initiate a new claim, kindly begin by clicking the 'Submit a claim' button below.",
          children: [
            {
              id: this.$nano.id(),
              label: "IUI (Involuntary Unemployment)",
              size: "6",
              workflowId: 13,
              description: [
                {
                  id: this.$nano.id(),
                  title: "Required Information",
                  class: "medium",
                  label:
                    "Please have the below documents ready to initiate an Involuntary Unemployment claim",
                  list: [
                    {
                      id: this.$nano.id(),
                      label: "- Record or Employment (ROE)",
                      condition: "OR",
                    },
                    {
                      id: this.$nano.id(),
                      label: "- Employer’s Statement (If ROE not available)",
                      link: {
                        label:
                          "Click here to download a pdf to be completed by your employer",
                        value:
                          "http://52.172.32.88/ezenterpriseAPI/template/Employer Statement.pdf",
                      },
                    },
                  ],
                },
                {
                  id: this.$nano.id(),
                  title: "Complete the Claim Form",
                  label:
                    "Fill out the claim form thoroughly, providing all the required information.",
                },
                {
                  id: this.$nano.id(),
                  title: "Follow Up",
                  label:
                    "Once you have submitted the claim form and the supporting documents, a PSG representative will contact you to follow up on the status of your claim. You will also receive a link by email which will allow you to track the progress of your claim.",
                },
              ],
            },
            {
              id: this.$nano.id(),
              label: "Sickness / Injury",
              size: "6",
              workflowId: 13,
              description: [
                {
                  id: this.$nano.id(),
                  title: "Required Information",
                  class: "medium",
                  label:
                    "All the below documents are required to initiate a Sickness/Injury Claim",
                  list: [
                    {
                      id: this.$nano.id(),
                      label: "- Employer’s Statement",
                    },
                    {
                      id: this.$nano.id(),
                      label: "- Physician’s Statement",
                    },
                  ],
                },
                {
                  id: this.$nano.id(),
                  title: "Complete the Claim Form",
                  label:
                    "Fill out the claim form thoroughly, providing all the required information.",
                },
                {
                  id: this.$nano.id(),
                  title: "Follow Up",
                  label:
                    "Once you have submitted the claim form and the supporting documents, a PSG representative will contact you to follow up on the status of your claim. You will also receive a link by email which will allow you to track the progress of your claim.",
                },
              ],
            },
            {
              id: this.$nano.id(),
              label: "Document Submission",
              size: "12",
              workflowId: 1013,
              description: [
                {
                  id: this.$nano.id(),
                  title: "",
                  size: "medium",
                  label:
                    "You can also download the claim documents by clicking on the link below and upload them to this portal.",
                },
                {
                  id: this.$nano.id(),
                  title: "",
                  size: "small",
                  label:
                    "Please note submitting forms through the below link might delay the process compared to submitting online forms on the link above.",
                },
              ],
            },
          ],
        },
        {
          id: this.$nano.id(),
          label: "Track your claim",
          size: "12",
          description:
            "To track the progress of your claim, simply click on the 'Track your claim' button below.",
          workflowId: 13,
        },
      ],
      drilledList: [],
      workflowId: "",
      workflow: {},
      formId: "",
      form: null,
      formModel: {},
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          name: "attachmentsCount",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          name: "commentsCount",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
      ],
      attachmentSheet: false,
      attachmentLoad: null,
      commentsSheet: false,
      commentText: "",
      commentsList: [],
      attachmentList: [],
      fileSheet: false,
      selectedFile: {},
      selectedProcess: {},
      checkList: [],
      docWarning: false,
      rowIndex: null,
      actions: [],
      processActionsAddOn: [],
      workflowFormModel: {},
      saveAction: "",
      generatePDF: 0,
      workflowRequestList: [],
      search: "",
      hasSelectedProcess: 0,
      processLevel: [],
      successModal: false,
      successModalClaim: false,
      refNo: "",
      alertModal: false,
      documentAlertModal: false,
      formEditAccess: false,
      formEditControls: [],
      readonlyControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      trackRequest: false,
      initiate: false,
      processSheet: false,
      name: "Track your request",
      label: "",
      filterBy: [],
      items: "",
      selectedFilter: [],
      title: "Track Portal",
      workflowRequestListData: [],
      advancedCommentsSheet: false,
      advancedCommentText: "",
      userList: [],
      totalItems: 0,
      itemsPerPage: 100,
      currentPage: 1,
      headerTitle: "",
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      repositoryDetails: [],
      repositoryField: [],
      showMetaDataEditModal: false,
      selectedUploadFile: {},
      uploadFilename: "",
      fileData: [],
      workflowModel: {},
      historyProcessId: 0,
      subWorkflowId: 0,
      subWorkflow: {},
      subProcessData: {},
      openSubProcessSheet: false,
      formUploadFiles: [],
      mandatory: {},
      fileControlDeleteFile: [],
      hasCheckFormValidate: false,
      formFields: [],
      subWorkflowFormList: [],
      fileUploadForControl: false,
      fileUploadInput: {},
      metaDataModal: {},
      metaDataTitle: "",
      formView: false,
      portalMasterFormId: 0,
      portalSettingJson: {},
      masterFormFields: [],
      fileUploadRequired: false,
      mandatoryDocument: [],
      ocrResult: [],
      btnAction: false,
      uploadAccess: false,
      showAttachmentSheet: false,
      showCommentsSheet: false,
      columns: [],
      selectedNode: "",
      inboxCount: 0,
      processCount: 0,
      commentAction: 1,
      showQr: false,
      uploadInterval: null,
      selectedFieldId: "",
      selectedFieldName: "",
      QRFileUploaded: false,
      mobileUploadFileJson: "",
      masterFormJson: "",
      hasChangeTableField: {},
      requiredDocuments: [],
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    isLogoRequired() {
      let origin = location.origin;
      if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "2" &&
        this.portalId === "4"
      ) {
        return false;
      }
      return true;
    },

    _logo() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return require(`@/assets/logo/sobha-logo.png`);
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return require(`@/assets/logo/psg-logo.png`);
      } else if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "2" &&
        this.portalId === "46"
      ) {
        return require(`@/assets/logo/hovr.png`);
      } else if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "23" &&
        this.portalId === "1"
      ) {
        return require(`@/assets/logo/bell-logo.png`);
      } else {
        return require(`@/assets/logo/name-${
          this.darkTheme ? "dark" : "light"
        }.png`);
      }
    },

    logoHeight() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return "32px";
      } else if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return "36px";
      } else if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "2" &&
        this.portalId === "46"
      ) {
        return "32px";
      } else if (
        origin === "https://trial.ezofis.com" &&
        this.tenantId === "23" &&
        this.portalId === "1"
      ) {
        return "36px";
      } else {
        return "24px";
      }
    },

    checkTenant() {
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return false;
      }
      return true;
    },

    isMaximized() {
      return this.portalWidth === "100%";
    },

    formName() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.name;
    },

    formDescription() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.description;
    },

    panels() {
      if (!this.formId) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.formId) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.formId) {
        return {};
      }
      return this.form.settings;
    },

    checkListTemplate() {
      if (this.checkList.length) {
        return this.checkList.filter((row) => row.template).length > 0;
      }
      return false;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    _items() {
      return this.workflowRequestList.map((item) => ({
        id: item.processId,
        icon: this.checkStatusIcon(item),
        color: this.checkStatusColor(item),
        iconTooltip: item.flowStatus == 1 ? "Completed" : "In-progress",
        requestNo: item.requestNo,
        raisedBy: item.raisedBy,
        raisedAt: this.$day.format(item.raisedAt),
        stage: item.stage,
        tabs: this.tabs,
        inbox: item.inbox,
        attachmentsCount: item.attachmentCount,
        commentsCount: item.commentsCount,
        lastAction: item.lastAction,
        ...this.getFormData(item),
        subWorkflow: item.subworkflowTransaction.map((subItem) => ({
          tabs: this.tabs,
          activityId: subItem.activityId,
          userId: subItem.activityUserId,
          color: subItem.flowStatus == 1 ? "green" : "orange",
          iconTooltip: subItem.flowStatus == 1 ? "Completed" : "In-progress",
          icon:
            subItem.flowStatus == 1
              ? "mdi-check-circle-outline"
              : "mdi-progress-clock",
          processId: subItem.processId,
          raisedAt: this.$day.format(subItem.raisedAt),
          raisedBy: subItem.raisedBy,
          raisedByUser: subItem.raisedByUserId,
          requestNo: subItem.requestNo,
          review: subItem.review,
          stageName: subItem.stageName,
          id: subItem.subWorkFlowId,
          stageType: subItem.stageType,
          stage: subItem.stageName,
          subWorkFlowId: subItem.subWorkFlowId,
          subWorkflowName: subItem.subWorkflowName,
          transactionId: subItem.transactionId,
          inbox:
            String(subItem.activityUserId) ===
            String(this.$store.state.session.id),
          attachmentsCount: subItem.attachmentCount,
          commentsCount: subItem.commentsCount,
          displaySubTable: false,
          actionUser: subItem.activityUserEmail,
          lastAction: subItem.lastAction,
          // fieldIndicatorList: this.getFieldIndicatorList(subItem),
          //formData:this.getsubFormData(subItem.subWorkFlowId, subItem.requestNo),
        })),
      }));
    },

    _columns() {
      let tableColumns = [];
      if (this.workflowRequestList.length) {
        tableColumns.push({
          id: this.$nano.id(),
          name: "requestNo",
          label: "Request No",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        });
      }
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            (field.label || field.type === "TABLE") &&
            field.settings.general.visibility !== "DISABLE"
          ) {
            tableColumns.push({
              id: this.$nano.id(),
              name: field.id,
              label: field.label,
              type: field.type,
              isVisible: true,
              isSortable: true,
              isGroupable: false,
            });
          }
        }
      });
      let status = [
        {
          id: this.$nano.id(),
          label: "Running",
          value: "running",
        },
        {
          id: this.$nano.id(),
          label: "Completed",
          value: "completed",
        },
      ];
      let stage = [];
      this.processLevel.forEach((level) => {
        stage.push({
          id: this.$nano.id(),
          label: level.label,
          value: level.label.toLowerCase(),
        });
      });

      tableColumns.push(
        {
          id: this.$nano.id(),
          name: "status",
          label: "Status",
          type: "SINGLE_SELECT",
          options: status,
          isVisible: false,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "stage",
          label: "Stage",
          type: "SINGLE_SELECT",
          options: stage,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "actionUser",
          label: "Action User",
          type: "SINGLE_SELECT",
          options: this.userList,
          isVisible: false,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedBy",
          label: "Raised By",
          type: "SINGLE_SELECT",
          options: this.userList,
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "raisedAt",
          label: "Raised On",
          type: "DATE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "id",
          label: "Process Id",
          type: "SHORT_TEXT",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
        }
      );
      return tableColumns;
    },

    mandatoryFilling() {
      if (this.mandatory.filledValue && this.mandatory.nonFilledValue) {
        return Math.floor(
          (this.mandatory.filledValue /
            (this.mandatory.filledValue + this.mandatory.nonFilledValue)) *
            100
        );
      } else if (this.mandatory.filledValue) {
        return 100;
      }
      return 0;
    },

    getFieldIndicatorList(process) {
      let formControls = [];
      let subForm = this.subWorkflowFormList.find(
        (form) => form.id === process.formData.formId
      );
      let panels = JSON.parse(subForm.formJson);
      panels.panels.forEach((panel) => {
        formControls.push(...panel.fields);
      });
      let filledValue = 0;
      let nonFilledValue = 0;

      let fieldsModel = process.formData.fields;

      formControls.forEach((control) => {
        if (this.isVisible(control.id)) {
          if (
            control.settings.validation.fieldRule === "REQUIRED" &&
            control.settings.general.visibility === "NORMAL"
          ) {
            if (control.settings.validation.answerIndicator === "YES") {
              this.showAnswerIndicator = true;
              if (Array.isArray(fieldsModel[control.id])) {
                if (fieldsModel[control.id].length) {
                  filledValue += 1;
                } else {
                  nonFilledValue += 1;
                }
              } else if (fieldsModel[control.id] === "<p></p>") {
                if (this.removeTags(fieldsModel[control.id])) {
                  filledValue += 1;
                } else {
                  nonFilledValue += 1;
                }
              } else if (fieldsModel[control.id]) {
                filledValue += 1;
              } else {
                nonFilledValue += 1;
              }
            }
          }
        }
      });
      return {
        filledValue: filledValue,
        nonFilledValue: nonFilledValue,
      };
    },
  },

  watch: {
    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          if (Object.keys(this.selectedProcess).length) {
            this.processRequest();
          } else {
            this.checkRequiredDocuments();
          }
        }
      },
    },

    itemsPerPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowId && this.workflowRequestList && this.itemsPerPage) {
          //this.getRequestList();
        }
      },
    },
    currentPage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowId && this.workflowRequestList && this.currentPage) {
          //this.getRequestList();
        }
      },
    },

    checkList: {
      deep: true,
      immediate: true,
      handler() {
        if (this.checkList.length) {
          let list = [];
          this.checkList.forEach((row) => {
            // console.log(row);
            if (row.required && !row.attach) {
              list.push(row);
            }
          });
          this.mandatoryDocument = list;
          this.requiredDocuments = list;
          // console.log(list);
        }
      },
    },

    fileUploadRequired: {
      immediate: true,
      handler() {
        if (this.checkList.length) {
          let list = [];
          this.checkList.forEach((row) => {
            // console.log(row);
            if (row.required && !row.attach) {
              list.push(row);
            }
          });
          // console.log(list);
          this.mandatoryDocument = list;
          this.requiredDocuments = list;
        }
      },

      // saveAction: {
      //   deep: true,
      //   immediate: true,
      //   handler() {
      //     if (this.btnAction) {
      //       if (this.saveAction) {
      //         this.$store.commit("showLoadingBarSave");
      //       } else {
      //         this.$store.commit("hideLoadingBarSave");
      //       }
      //     }
      //   },
      // },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.selectedProcess.processId) {
          this.getAttachments();
          if (this.selectedProcess.flowStatus === "0") {
            this.attachmentLoad = setInterval(() => {
              this.getAttachments(0, true);
            }, 15000);
          }
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },

    form: {
      deep: true,
      immediate: true,
      handler() {
        if (this.form) {
          if (this.form.formJson) {
            this.getColumns();
          }
        }
      },
    },
  },

  created() {
    if (
      location.origin === "https://demo.ezofis.com" ||
      location.origin === "http://52.172.32.88"
    ) {
      if (this.portalId == 1) {
        this.drilledList = this.tabList;
      } else {
        this.tabList = [];
        this.getPortal();
      }
    } else {
      this.tabList = [];
      this.getPortal();
    }
    this.getUsers();
  },

  methods: {
    closeQR(id) {
      this.showQr = false;
      this.QRexpired(id);
    },

    checkMobileUpload(id) {
      console.log(id);
      this.uploadInterval = setInterval(() => {
        this.watchMobileUpload(id);
      }, 5000);
    },

    async QRexpired(id, timeOut) {
      if (!timeOut) {
        this.showQr = false;
      }
      const { payload } = await repository.getQrFileUploadById(id);
      console.log(payload, "payload1");
      let jsonData = JSON.parse(payload.jsonData);
      if (!payload.status) {
        let parsedJsonData = jsonData[0];
        parsedJsonData = { ...parsedJsonData, expired: true };

        let input = {
          id: id,
          url: payload.url,
          jsonData: JSON.stringify([parsedJsonData]),
          status: 0,
        };
        const { error } = await repository.updateJson(id, input);

        if (error) {
          return;
        }
        clearInterval(this.uploadInterval);
        this.uploadInterval = null;
        if (this.processSheet) {
          this.$alert.info(`QR expired`);
        }
      }
    },

    async watchMobileUpload(id) {
      const { error, payload } = await repository.getQrFileUploadById(id);
      console.log(error, payload, "error, payload");

      let jsonData = JSON.parse(payload.jsonData);
      console.log(jsonData, "jsonData", "payload.status", payload.status);
      if (payload.status) {
        clearInterval(this.uploadInterval);
        this.uploadInterval = null; //
        let parsedJsonData = jsonData[0];
        this.mobileUploadFileJson = parsedJsonData;
        if (this.repositoryDetails.fieldsType === "STATIC") {
          let label = parsedJsonData.attachmentList[0].name;
          this.uploadFilename = label;

          //rename higher level name
          const highestLevelObject = this.repositoryField.reduce(
            (prev, curr) => (curr.level > prev.level ? curr : prev),
            this.repositoryField[0]
          );

          const highestLevelId = highestLevelObject.id;

          if (this.attachmentList.length) {
            let index = this.attachmentList.length - 1;
            await this.getFileData(this.attachmentList[index], "file");
          }

          const fileDataItem = this.fileData.find(
            (item) => highestLevelId == item.id
          );
          const newObjectToPush = {
            id: highestLevelObject.id,
            name: highestLevelObject.name,
            value: label,
            type: highestLevelObject.dataType,
          };
          if (!this.fileData.length) {
            this.fileData.push(newObjectToPush);
          } else {
            fileDataItem.value = label;
          }
          // console.log(this.attachmentList,"files, control, fieldId, aiSettings hasfiel")
          // console.log(label,"files, control, fieldId, aiSettings hasfiel")
          // console.log(this.fileData,"files, control, fieldId, aiSettings hasfiel")

          // check all mandatory filled
          const mandatoryItems = this.repositoryField
            .filter((item) => item.isMandatory === true)
            .map((item) => item.id);

          const allMandatoryFilled = mandatoryItems.every((mandatoryId) => {
            const fileItem = this.fileData.find(
              (item) => item.id === mandatoryId
            );
            return fileItem && fileItem.value !== "";
          });

          this.showMetaDataEditModal = !allMandatoryFilled;

          if (allMandatoryFilled) {
            const newObject = {};

            this.fileData.forEach((item) => {
              newObject[item.name] = item.value !== "" ? item.value : "";
            });
            this.fileUploadForControl = true;
            this.saveMetaData(newObject);
          } else {
            this.fileUploadForControl = true;
            this.showMetaDataEditModal = true;
          }
        } else {
          let updatedData = {
            name: parsedJsonData.attachmentList[0].name,
            size: parsedJsonData.attachmentList[0].size,
            file: "",
            fileId: parsedJsonData.attachmentList[0].id,
            id: parsedJsonData.attachmentList[0].id,
            fileControl: true,
            formAttach: true,
            createdBy: parsedJsonData.attachmentList[0].createdByEmail,
            workflowId: this.workflow.id,
            processId: this.selectedProcess.processId,
            fieldId: parsedJsonData.fieldId,
            fileDataValidate: "",
          };
          console.log(updatedData, "updatedData");
          this.attachmentList.push(updatedData);
          // this.formUploadFiles.push(updatedData);

          let workflowModelValue = {
            name: parsedJsonData.attachmentList[0].name,
            size: parsedJsonData.attachmentList[0].size,
            file: "",
            fileId: parsedJsonData.attachmentList[0].id,
            uploadedPercentage: 100,
            createdBy: parsedJsonData.attachmentList[0].createdByEmail,
          };

          this.formFields.forEach((field) => {
            if (
              field.type === "FILE_UPLOAD" &&
              this.workflowModel[field.value] &&
              field.value == parsedJsonData.fieldId
            ) {
              this.workflowModel[field.value].push(workflowModelValue);
              // console.log( this.workflowModel[field.value],"this.workflowModel[field.value]")
            }
          });

          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        }

        // this.addFileInAttachment();
        this.showQr = false;
        this.QRFileUploaded = true;

        // console.log(updatedData, "updatedData");
      } else {
        console.log("Waiting for uploaded");
        // console.log(this.processSheet,"processSheet")
        if (!this.processSheet && !this.openSubProcessSheet) {
          clearInterval(this.uploadInterval);
          this.uploadInterval = null; //
        } else if (!this.showQr) {
          clearInterval(this.uploadInterval);
          this.uploadInterval = null; //
        }
      }
      // this.repositoryId = jsonData[0].repositoryId;
      // this.url =  jsonData[0].url;
    },

    async addFileInAttachment() {
      let parsedJsonData = this.mobileUploadFileJson;
      let input = [
        {
          id: parsedJsonData.attachmentList[0].id,
          status: "NOT_INDEXED",
          OCRPages: [1],
          OCRType: "MANUAL",
          OCRTool: "",
          OCRPattern: false,
        },
      ];
      console.log(input, "input");
      console.log("metaDataModal", this.metaDataModal);

      let felds = [];

      Object.keys(this.metaDataModal).forEach((key) => {
        console.log(`Key: ${key}, Value: ${this.metaDataModal[key]}`);

        this.repositoryField.forEach((obj) => {
          Object.keys(obj).forEach((repoKey) => {
            // Check if repoKey is "name" and the value matches with metaDataModal[key]

            if (repoKey == "name" && obj[repoKey] == key) {
              console.log(`Key: ${repoKey}, Value: ${obj[repoKey]}`);

              // Create a new object with name, value, and type (dataType)
              let newObj = {
                name: key,
                value: this.metaDataModal[key],
                type: obj["dataType"], // Fixed accessing dataType
              };

              felds.push(newObj); // Push the new object to felds array
            }
          });
        });
      });

      console.log(felds);
      this.showMetaDataEditModal = true;
      const { error, payload } = await uploadAndIndex.uploadSetStatus(input);
      if (error) {
        console.error(error);
        return;
      } else {
        let value = parseInt(JSON.parse(payload)[0]);

        console.log(value, "payload");
        let exportId = value;
        console.log(exportId, "exportId");
        let fileInput = {
          repositoryId: this.workflow.repositoryId,
          itemId: 0,
          status: "INDEXED",
          fields: felds,
        };
        if (fileInput) {
          console.log(fileInput, "fileInput");
          const { error, payload } = await uploadAndIndex.updateFile(
            exportId,
            fileInput
          );
          if (error) {
            console.error(error);
            return;
          } else {
            console.log(payload);
            console.log(parsedJsonData, "file");
            let updatedData = {
              name: parsedJsonData.attachmentList[0].name,
              size: parsedJsonData.attachmentList[0].size,
              file: "",
              fileId: exportId,
              id: exportId,
              fileControl: true,
              formAttach: true,
              createdBy: parsedJsonData.attachmentList[0].createdByEmail,
              workflowId: this.workflow.id,
              processId: this.selectedProcess.processId,
              fieldId: parsedJsonData.fieldId,
              fileDataValidate: "",
            };
            this.attachmentList.push(updatedData);
            // this.formUploadFiles.push(updatedData);

            let workflowModelValue = {
              name: parsedJsonData.attachmentList[0].name,
              size: parsedJsonData.attachmentList[0].size,
              file: "",
              fileId: exportId,
              uploadedPercentage: 100,
              createdBy: parsedJsonData.attachmentList[0].createdByEmail,
            };

            this.formFields.forEach((field) => {
              if (
                field.type === "FILE_UPLOAD" &&
                this.workflowModel[field.value] &&
                field.value == parsedJsonData.fieldId
              ) {
                this.workflowModel[field.value].push(workflowModelValue);
                // console.log( this.workflowModel[field.value],"this.workflowModel[field.value]")
              }
            });

            this.tabs.find((tab) => {
              if (tab.value === "ATTACHMENTS") {
                tab.count = this.attachmentList.length;
              }
            });
          }
        }
      }
    },

    async generateQrCode(fieldId) {
      this.selectedFieldId = fieldId;
      let name = await this.getLabel(fieldId);
      this.selectedFieldName = name;
      this.showQr = true;
      this.QRFileUploaded = false;
    },
    getlist(type) {
      // console.log(this.darkTheme,"darkTheme")
      // this.processCount = 0;
      // this.InboxCount = 0;
      if (!type == this.inbox) {
        this.inbox = type;
        this.getRequestList();
      }
    },

    handleFormViewUpdate(value) {
      this.formView = value;
      if (!value) {
        this.processSheet = false;
      }
    },

    checkDocument() {
      this.docWarning = false;
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          if (row.required && !row.attach) {
            this.docWarning = true;
          }
        });
      }
    },

    checkListDocument() {
      this.checkList.forEach((doc) => {
        if (doc.conditions) {
          if (doc.conditions.length) {
            doc.required = false;
            doc.conditions.forEach((condition) => {
              if (doc.groupLogic === "ANY" && doc.required) {
                return;
              }
              let fieldType =
                this.formFields.find((field) => field.value === condition.name)
                  ?.type || "";
              let conditionValue = condition.value;
              let fieldValue =
                this.selectedProcess.formData.fields[condition.name];
              if (fieldType === "NUMBER") {
                conditionValue = Number(conditionValue);
                fieldValue = Number(fieldValue);
              }
              switch (condition.logic) {
                case "IS_EQUALS_TO":
                  if (fieldValue === conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_NOT_EQUALS_TO":
                  if (fieldValue !== conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "CONTAINS":
                  if (fieldValue.indexOf(conditionValue) > -1) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_GREATER_THAN":
                  if (fieldValue > conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_GREATER_THAN_OR_EQUALS_TO":
                  if (fieldValue >= conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_LESSER_THAN":
                  if (fieldValue < conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
                case "IS_LESSER_THAN_OR_EQUALS_TO":
                  if (fieldValue <= conditionValue) {
                    doc.required = true;
                  } else {
                    doc.required = false;
                  }
                  break;
              }
            });
            if (doc.required || doc.visible) {
              doc.show = true;
            } else {
              doc.show = false;
            }
          } else {
            doc.show = true;
          }
        } else {
          doc.show = true;
        }
        return doc;
      });
    },

    resetList() {
      this.activeItem = {};
      this.breadcrumbs = [];
      this.drilledList = this.tabList;
    },

    checkStatusIcon(process) {
      if (process.flowStatus == 1) {
        if (process.lastAction.toLowerCase().indexOf("reject") > -1) {
          return "eva-close-circle-outline";
        } else {
          return "eva-checkmark-circle-2-outline";
        }
      } else {
        return "mdi-progress-clock";
      }
    },

    checkStatusColor(process) {
      if (process.flowStatus == 1) {
        if (process.lastAction.toLowerCase().indexOf("reject") > -1) {
          return "red";
        } else {
          return "positive";
        }
      } else {
        return "orange";
      }
    },

    async showLinks(tab, processId) {
      if (tab === "ATTACHMENTS") {
        if (processId) {
          this.attachmentList = [];
          this.selectedProcess = this.workflowRequestList.find(
            (process) => process.processId === processId
          );
          if (this.selectedProcess.inbox) {
            this.selectedNode = "inbox";
          } else {
            this.selectedNode = "completed";
          }
          this.checkList = [];
          let workflowJson = JSON.parse(this.workflow.flowJson);
          let block = workflowJson.blocks.find(
            (block) => block.id === this.selectedProcess.activityId
          );
          if (block) {
            if (block.settings.fileSettings) {
              if (block.settings.fileSettings.fileCheckList.length) {
                this.checkList = block.settings.fileSettings.fileCheckList;
                this.checkList.forEach((row) => {
                  row.uid = this.$nano.id();
                  row.attach = false;
                  this.fileUploadRequired = !this.fileUploadRequired;
                });
                this.checkListDocument();
              }
            }
          }
        }
        this.showAttachmentSheet = true;
      } else if (tab === "COMMENTS") {
        if (processId) {
          this.selectedProcess = this.workflowRequestList.find(
            (process) => process.processId === processId
          );
          this.commentsList = [];
          if (this.selectedProcess.inbox) {
            this.selectedNode = "inbox";
          } else {
            this.selectedNode = "completed";
          }
        }
        this.showCommentsSheet = true;
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        this.historyProcessId = this.selectedProcess.processId;
      }
    },

    async showSubLinks(tab, mainProcess, subItem) {
      // this.subWorkflowId = subItem.id;
      // await this.getSubWorkflow(this.subWorkflowId);

      if (tab === "ATTACHMENTS") {
        if (subItem.processId) {
          this.selectedProcess = mainProcess.subWorkflow.find(
            (process) => process.processId === subItem.processId
          );
          this.attachmentList = [];
        }
        this.showAttachmentSheet = true;
      } else if (tab === "COMMENTS") {
        if (subItem.processId) {
          this.selectedProcess = mainProcess.subWorkflow.find(
            (process) => process.processId === subItem.processId
          );
          this.commentsList = [];
          await this.getComments(subItem.id);
        }
        this.showCommentsSheet = true;
      }
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            }
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
        } else {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        //window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    download(id) {
      window.open(
        `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${id}`
      );
    },

    downloadAll() {
      this.checkList.forEach((row) => {
        if (row.id && row.template) {
          window.open(
            `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${row.id}`
          );
        }
      });
    },

    attachTemplate(uid) {
      this.rowIndex = uid;
      this.$refs.attachments.click();
    },

    searchList() {
      if (this.search) {
        this.workflowRequestList = this.workflowRequestListData.filter(
          (row) => {
            for (let cell in row) {
              let index = this._columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell])
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }
        );
      } else {
        this.workflowRequestList = this.workflowRequestListData;
      }
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    getLabel(id, type) {
      let controlLabel = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (type === "table") {
            const column = field.settings.specific.tableColumns.find(
              (column) => column.id === id
            );
            if (column) {
              controlLabel = column.label;
            }
          } else {
            if (field.id === id) {
              controlLabel = field.label;
              return;
            }
          }
        }
      });
      return controlLabel;
    },

    validateType(key) {
      let proceed = true;
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.id === key) {
            if (field.type === "TABLE") {
              proceed = false;
              return;
            }
          }
        }
      });
      return proceed;
    },

    updateFilterBy() {},

    getMandatory(key) {
      const value = this.getLabel(key);

      for (let panel of this.panels) {
        for (let field of panel.fields) {
          if (field.label === value) {
            return field.settings.validation.fieldRule === "REQUIRED";
          }
        }
      }
      return false;
    },

    getFormData(item, exportData) {
      const data = {};
      if (item.formData) {
        if (item.formData.fields) {
          for (let key of Object.keys(item.formData.fields)) {
            if (exportData) {
              const label = this.getLabel(key);
              data[label] = this.removeTags(item.formData.fields[key]);
            } else {
              data[key] = this.removeTags(item.formData.fields[key]);
            }
          }
        }
      }
      return data;
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    logOut() {
      window.localStorage.clear();
      this.$router.push({ name: "portals-signin" });
    },

    export_Data() {
      // this.$store.commit("showLoadingBar");
      // const { error, payload } = await report.getWorkflowData(this.workflowId, {
      //   filterBy: this.filterBy,
      //   itemsPerPage: 10,
      //   currentPage: 1,
      //   mode: "",
      // });
      // this.$store.commit("hideLoadingBar");
      // if (error) {
      //   this.$alert.error(error);
      //   return;
      // }
      let tableData = [];
      tableData = this.workflowRequestList.map((item) => ({
        "Request Number": item.requestNo,
        "Raised By": item.raisedBy,
        "Raised At": this.$day.format(item.raisedAt),
        Stage: item.stage,
        ...this.getFormData(item, true),
      }));
      // payload.data.forEach((row) => {
      //   row.value.forEach((process) => {
      //     tableData.push({
      //       ...process,
      //     });
      //   });
      // });
      if (tableData.length) {
        exportData(tableData);
      }
    },

    // API Functions

    async getPortal() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await portal.getPortal(this.portalId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.settingsJson) {
          let settings = JSON.parse(payload.settingsJson);
          if (
            settings.authentication &&
            settings.authentication.loginType === "MASTER_LOGIN"
          ) {
            this.portalMasterFormId = settings.authentication.formId;
            this.portalSettingJson = settings.authentication;
            this.getMasterForm();
          }
        }
        this.title = payload.name;
        this.formId = "";
        this.form = null;
        this.workflowId = "";
        this.checkList = [];
        this.saveAction = "";
        this.workflowFormModel = {};
        this.actions = [];
        this.processLevel = [];
        this.docWarning = false;
        this.attachmentList = [];
        this.commentsList = [];
        this.workflowId = payload.workflowId;
        this.trackRequest = true;
        this.subWorkflowFormList = [];
        this.getWorkflow();
      }
    },

    async getWorkflow() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflow(this.workflowId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.workflow = payload;
      let workflowJson = JSON.parse(this.workflow.flowJson);
      let startBlock = workflowJson.blocks.find(
        (block) => block.type === "START"
      );

      if (startBlock) {
        if (startBlock.settings.initiateBy.indexOf("PORTAL") > -1) {
          this.initiate = true;
        }
      }

      let subWorkflowIds = [];
      workflowJson.blocks.forEach((block) => {
        if (
          block.type === "START" ||
          block.type === "INTERNAL_ACTOR" ||
          block.type === "END"
        ) {
          this.processLevel.push({
            id: block.id,
            label: block.settings.label,
          });
        } else if (block.type === "WORKFLOW_CONNECTOR") {
          //
          subWorkflowIds.push(block.settings.subWorkflowId);
        }
      });
      if (subWorkflowIds.length) {
        //await this.getSubWorkflows(subWorkflowIds);
      }
      if (workflowJson.settings.general.initiateUsing.formId) {
        this.getForm(workflowJson.settings.general.initiateUsing.formId);
      }
      this.getRepository();
    },

    async getSubWorkflow() {
      const { error, payload } = await workflow.getWorkflow(this.subWorkflowId);
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.subWorkflow = payload;
    },

    async getForm(fId) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getForm(fId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.formId = fId.toString();
      this.form = JSON.parse(payload.formJson);

      const panels = [...this.form.panels, ...this.form.secondaryPanels];

      if (!panels.length) {
        return;
      }
      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      fields.forEach((field) => {
        if (
          field.type !== "DIVIDER" &&
          field.type !== "LABEL" &&
          field.type !== "PARAGRAPH" &&
          (field.label || field.type === "TABLE") &&
          field.settings.general.visibility !== "DISABLE"
        ) {
          this.formFields.push({
            id: this.$nano.id(),
            label: field.label || field.type,
            value: field.id,
            type: field.type,
            settings: field.settings,
          });
        }
      });
      if (this.trackRequest) {
        this.getRequestList(true);
      }
    },

    async getComments(subWorkflowId) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        subWorkflowId || this.workflow.id,
        this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.selectedProcess.processId) {
          this.$store.commit("showLoadingBar");
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.commentText,
              showTo: this.commentAction,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.commentText = "";
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.commentText = "";
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.selectedProcess.processId) {
          this.$store.commit("showLoadingBar");
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.advancedCommentText,
              showTo: this.commentAction,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
      }
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.workflow.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      this.repositoryField = payload.fields;
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });

      data.forEach((key) => {
        let hasName = this.formFields.find(
          (item) => lowerCase(item.label) === lowerCase(key.name)
        );
        if (hasName && key.value) {
          this.workflowModel[hasName.value] = key.value;
        }
      });

      if (this.QRFileUploaded) {
        this.metaDataModal = modal;
        this.addFileInAttachment();
        this.QRFileUploaded = false;
        return;
      }

      if (this.fileUploadForControl) {
        this.metaDataModal = modal;
        let input = this.fileUploadInput;
        this.uploadFilesFromForm(
          input.files,
          input.control,
          input.fieldId,
          JSON.stringify(data),
          input.aiSettings
        );
      } else {
        this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
      }
    },

    closeMetaDataEditModal() {
      this.showMetaDataEditModal = false;
      if (this.$refs.attachments) {
        this.$refs.attachments.value = null;
      }
    },

    async uploadWflowAttachments(e) {
      this.fileUploadForControl = false;
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        this.uploadFilename = e.target.files[0].name;
        this.showMetaDataEditModal = true;
      } else {
        this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e, fields) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.workflow.id);
        formData.append("repositoryId", this.workflow.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        formData.append("fields", "");
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.rowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.rowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");

        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          e.target.value = "";
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.rowIndex) {
              row.attach = true;
              this.fileUploadRequired = !this.fileUploadRequired;
              return;
            }
          });
          //this.checkDocument();
        }
        e.target.value = "";
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        if (fields) {
          this.fileData = JSON.parse(fields);
          formData.append("fields", fields);
        }
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.rowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.rowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBar");
          const { status, data } = response;
          e.target.value = "";
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.rowIndex,
          });
          this.$alert.success(`Document attached`);
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.rowIndex) {
                row.attach = true;
                this.fileUploadRequired = !this.fileUploadRequired;
                return;
              }
            });
            //this.checkDocument();
          }
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments(subWorkflowId = 0, autoLoad = false) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      if (this.selectedProcess.processId) {
        this.checkList = [];
        let workflowJson = JSON.parse(this.workflow.flowJson);
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );
        if (block) {
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
                this.fileUploadRequired = !this.fileUploadRequired;
              });
              //this.checkDocument();
            }
          }
        }
        let count = this.attachmentList.length;
        if (!autoLoad) {
          this.$store.commit("showLoadingBar");
        }
        const { error, payload } = await workflow.getAttachments(
          subWorkflowId || this.workflow.id,
          this.selectedProcess.processId
        );
        if (!autoLoad) {
          this.$store.commit("hideLoadingBar");
        }

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = payload.length;
            }
          });
          if (count !== payload.length) {
            this.attachmentList = payload.map((item) => {
              return {
                ...item,
                checked: false,
              };
            });
          }
        } else {
          this.attachmentList = [];
        }
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            let filterAttach = this.attachmentList.filter((file) => {
              let filename = file.name.substr(0, file.name.lastIndexOf("."));
              if (row.name === filename) {
                return file;
              }
            });
            if (filterAttach.length) {
              row.attach = true;
            } else {
              row.attach = false;
            }
            this.fileUploadRequired = !this.fileUploadRequired;
          });
          this.checkListDocument();
          this.requiredDocuments = this.checkList.filter(
            (row) => !row.attach && row.show
          );
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        }
      }
    },

    async attachmentDelete(file) {
      if (!file.fileControl) {
        this.$store.commit("showLoadingBar");
        if (file.initiate) {
          if (this.repositoryDetails.fieldsType === "STATIC") {
            const { error } = await uploadAndIndex.deleteIndexedFiles([
              file.id,
            ]);
            if (error) {
              this.$alert.error(error);
              return;
            }
          } else {
            const { error } = await uploadAndIndex.deleteUploadedFiles([
              file.id,
            ]);
            if (error) {
              this.$alert.error(error);
              return;
            }
          }
          this.$store.commit("hideLoadingBar");
          this.attachmentList = this.attachmentList.filter(
            (row) => row.id !== file.id
          );
          this.checkList.forEach((row) => {
            if (row.uid === file.uid) {
              row.attach = false;
              this.fileUploadRequired = !this.fileUploadRequired;
            }
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        } else {
          const { error } = await repository.fileDelete(
            this.workflow.repositoryId,
            file.id
          );
          if (error) {
            this.$alert.error(error);
            return;
          }
          this.$store.commit("hideLoadingBar");
          this.getAttachments();
        }
      } else {
        let field = this.workflowModel[file.fieldId];
        if (field.length) {
          let hasField = field.findIndex((item) => {
            return item.fileId === file.id;
          });
          // console.log(hasField);
          if (hasField >= 0) {
            this.workflowModel[file.fieldId].splice(hasField, 1);
          }

          let hasValue = this.formUploadFiles.findIndex((item) => {
            return item.fileId === file.id && item.fileId === file.fileId;
          });
          // console.log(hasValue);
          if (hasValue >= 0) {
            this.formUploadFiles.splice(hasField, 1);
          }
          if (file.control.length) {
            file.control.forEach((item) => {
              this.workflowModel[item] = "";
            });
          }
        }
        let hasFile = this.attachmentList.findIndex((file) => {
          return file.fileControl;
        });
        // console.log(hasFile);
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    checkRequiredDocuments() {
      let docRequired = [];
      docRequired = this.checkList.filter((row) => row.required && !row.attach);
      if (docRequired.length) {
        let origin = location.origin;
        if (
          origin === "https://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          if (this.portalId == 1) {
            this.documentAlertModal = true;
          } else {
            this.alertModal = true;
          }
        } else {
          if (this.docWarning) {
            this.alertModal = true;
          } else if (this.saveAction !== "Save") {
            this.$alert.warning("Mandatory documents required");
            this.saveAction = "";
            this.workflowFormModel = {};
          } else {
            this.initiateRequest();
          }
        }
      } else {
        this.initiateRequest();
      }
    },

    closeAlertModal() {
      this.saveAction = "";
      this.workflowFormModel = {};
      this.alertModal = false;
    },

    closeDocumentAlertModal() {
      this.saveAction = "";
      this.workflowFormModel = {};
      this.documentAlertModal = false;
    },

    async initiateRequest() {
      let uploadedIdList = [];
      if (this.attachmentList.length) {
        this.attachmentList.forEach((file) => {
          if (!file.formAttach) {
            uploadedIdList.push(file.id);
          }
        });
      }

      let docRequired = [];
      if (this.docWarning) {
        docRequired = this.checkList.filter(
          (row) => row.required && !row.attach
        );
      }
      // let defaultCommentText =
      //   "Request submitted portal " + window.location.href;
      // this.commentsList.push({
      //   comments: defaultCommentText,
      //   createdAt: new Date().toISOString(),
      //   createdBy: this.$store.state.session.id,
      // });

      let formUpload = [];

      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && this.workflowModel[field.value]) {
          let ids = [];
          this.workflowModel[field.value].forEach((item) => {
            if (item.fileId) {
              ids.push(item.fileId);
            }
          });
          if (ids.length) {
            formUpload.push({
              jsonId: field.value,
              fileIds: ids,
              rowid: 0,
            });
          }
          delete this.workflowFormModel[field.value];
        } else if (field.type === "TABLE") {
          let tableFiles = this.attachmentList.filter(
            (file) => file.tableControl
          );
          console.log(tableFiles);
          tableFiles.forEach((file) => {
            formUpload.push({
              jsonId: file.fieldId,
              fileIds: [file.id],
              rowid: file.rowIndex,
            });
            console.log(
              this.workflowFormModel[file.tableId][file.rowIndex - 1][
                file.fieldId
              ]
            );
            this.workflowFormModel[file.tableId][file.rowIndex - 1][
              file.fieldId
            ] = [{}];
          });
        }
      });

      let inputData = {
        workflowId: this.workflow.id,
        review: this.saveAction === "Save" ? "" : this.saveAction,
        comments: this.commentsList,
        formData: {
          formId: this.formId,
          fields: this.workflowFormModel,
          formUpload: formUpload,
        },
        fileIds: uploadedIdList,
        fileChecklistStatus: this.docWarning ? 1 : 0,
        fileInfo: docRequired,
        portalId: this.portalId,
        hasFormPDF: this.generatePDF,
      };
      this.saveAction = "";
      this.workflowFormModel = {};
      //console.log(inputData);
      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.alertModal = false;
      this.$alert.success(`${payload.requestNo} Request Initiated`);
      this.refNo = payload.requestNo;
      this.refresh(payload.requestNo, inputData.review);
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.portalId == 1) {
          this.successModalClaim = true;
        } else {
          this.successModal = true;
        }
      } else {
        this.successModal = true;
      }
      this.resetList();
    },

    async processRequest() {
      let formUpload = [];

      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && this.workflowModel[field.value]) {
          let ids = [];
          this.workflowModel[field.value].forEach((item) => {
            if (item.fileId) {
              ids.push(item.fileId);
            }
          });
          if (ids.length) {
            formUpload.push({
              jsonId: field.value,
              fileIds: ids,
              rowid: 0,
            });
          }
          delete this.workflowFormModel[field.value];
        } else if (field.type === "TABLE") {
          let tableFiles = this.attachmentList.filter(
            (file) => file.tableControl
          );
          console.log(tableFiles);
          tableFiles.forEach((file) => {
            formUpload.push({
              jsonId: file.fieldId,
              fileIds: [file.id],
              rowid: file.rowIndex,
            });
            console.log(
              this.workflowFormModel[file.tableId][file.rowIndex - 1][
                file.fieldId
              ]
            );

            let hasFile = this.workflowFormModel[file.tableId][
              file.rowIndex - 1
            ][file.fieldId].findIndex((table) => {
              return table.name;
            });
            if (hasFile > -1) {
              this.workflowFormModel[file.tableId][file.rowIndex - 1][
                file.fieldId
              ].splice(hasFile, 1);
            }
            // this.workflowFormModel[file.tableId][file.rowIndex - 1][
            //   file.fieldId
            // ] = [];
          });
        }
      });
      let inputData = {};
      if (this.formId) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.selectedProcess.formData.formEntryId,
            fields: this.workflowFormModel,
            formUpload: formUpload,
          },
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};

      this.$store.commit("showLoadingBarSave");
      const { error } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (inputData.review === "") {
        this.$alert.info(
          `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
        );
      } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
        this.$alert.danger(
          `${this.selectedProcess.requestNo} Request Rejected Successfully`
        );
      } else {
        this.$alert.success(
          `${this.selectedProcess.requestNo} Request Processed`
        );
      }

      if (this.fileControlDeleteFile.length) {
        this.fileControlDeleteFile.forEach((file) => {
          this.fileControlDeletedFile(file);
        });

        this.fileControlDeleteFile = [];
      }
      this.processSheet = false;
      this.getRequestList();
    },

    async getRequestList(loadAll) {
      if (this.workflow.id) {
        this.loadingBarContent = true;
        this.workflowRequestList = [];
        if (this.inbox) {
          await this.getInboxList();
          await this.getSubWorkflowInboxList();
          if (loadAll) {
            await this.getSentCount();
            await this.getCompletedCount();
          }
        } else {
          await this.getSentList();
          await this.getCompletedList();
        }
        this.workflowRequestListData = this.workflowRequestList.map((item) => ({
          ...item,
          ...this.getFormData(item),
        }));
        this.loadingBarContent = false;
      }
    },

    async getSentCount() {
      const { error, payload } = await workflow.getSentList(this.workflow.id, {
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let isFound = this.workflowRequestList.filter(
            (row) => row.processId === process.processId
          );
          if (isFound.length === 0) {
            let reqInbox = true;
            if (process.subworkflowTransaction) {
              if (process.subworkflowTransaction.length) {
                process.subworkflowTransaction.forEach((req) => {
                  if (req.activityUserId === this.$store.state.session.id) {
                    reqInbox = false;
                    return;
                  }
                });
              }
            }
            if (reqInbox) {
              this.processCount += 1;
            }
          }
        });
      });
    },

    async getCompletedCount() {
      const { error, payload } = await workflow.getCompletedList(
        this.workflow.id,
        {
          filterBy: [],
          itemsPerPage: 100,
          currentPage: 1,
        }
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let isFound = this.workflowRequestList.filter(
            (row) => row.processId === process.processId
          );
          if (isFound.length === 0) {
            this.processCount += 1;
          }
        });
      });
    },

    async getSentList() {
      const { error, payload } = await workflow.getSentList(this.workflow.id, {
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.totalItems = this.totalItems + payload.meta?.totalItems || 0;
      // this.processCount = 0;
      // this.processCount = payload.meta?.totalItems || 0;
      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let isFound = this.workflowRequestList.filter(
            (row) => row.processId === process.processId
          );
          if (isFound.length === 0) {
            let reqInbox = true;
            if (process.subworkflowTransaction) {
              if (process.subworkflowTransaction.length) {
                process.subworkflowTransaction.forEach((req) => {
                  if (req.activityUserId === this.$store.state.session.id) {
                    reqInbox = false;
                    return;
                  }
                });
              }
            }
            if (reqInbox) {
              let stepCount = 0;
              let workflowJson = JSON.parse(this.workflow.flowJson);
              let check = true;
              workflowJson.blocks.forEach((block) => {
                if (block.id !== process.activityId && check) {
                  stepCount += 1;
                } else {
                  if (process.flowStatus == 1) {
                    stepCount += 1;
                  }
                  check = false;
                }
              });
              this.workflowRequestList.push({
                ...process,
                step: stepCount,
                visible: true,
                expand: false,
                inbox: false,
              });
              this.workflowRequestList.sort(
                (a, b) =>
                  new Date(b.transaction_createdAt) -
                  new Date(a.transaction_createdAt)
              );
            }
          }
        });
      });
      this.processCount = this.workflowRequestList.length;
    },

    async getCompletedList() {
      const { error, payload } = await workflow.getCompletedList(
        this.workflow.id,
        {
          filterBy: [],
          itemsPerPage: 100,
          currentPage: 1,
        }
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.totalItems = this.totalItems + payload.meta?.totalItems || 0;
      //this.processCount = this.processCount + payload.meta?.totalItems || 0;
      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let isFound = this.workflowRequestList.filter(
            (row) => row.processId === process.processId
          );
          if (isFound.length === 0) {
            let stepCount = 0;
            let workflowJson = JSON.parse(this.workflow.flowJson);
            let check = true;
            workflowJson.blocks.forEach((block) => {
              if (block.id !== process.activityId && check) {
                stepCount += 1;
              } else {
                if (process.flowStatus == 1) {
                  stepCount += 1;
                }
                check = false;
              }
            });
            this.workflowRequestList.push({
              ...process,
              step: stepCount,
              visible: true,
              expand: false,
              inbox: false,
            });
            this.workflowRequestList.sort(
              (a, b) =>
                new Date(b.transaction_createdAt) -
                new Date(a.transaction_createdAt)
            );
            this.workflowRequestList.sort(
              (a, b) => new Date(b.inbox) - new Date(a.inbox)
            );
          }
        });
      });
      this.processCount = this.workflowRequestList.length;
    },

    async getInboxList() {
      const { error, payload } = await workflow.getInboxList(this.workflow.id, {
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.totalItems = payload.meta?.totalItems || 0;

      //this.inboxCount = payload.meta?.totalItems || 0;

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let isFound = this.workflowRequestList.filter(
            (row) => row.processId === process.processId
          );
          if (isFound.length === 0) {
            let stepCount = 0;
            let workflowJson = JSON.parse(this.workflow.flowJson);
            let check = true;
            workflowJson.blocks.forEach((block) => {
              if (block.id !== process.activityId && check) {
                stepCount += 1;
              } else {
                if (process.flowStatus == 1) {
                  stepCount += 1;
                }
                check = false;
              }
            });
            this.workflowRequestList.push({
              ...process,
              step: stepCount,
              visible: true,
              expand: false,
              inbox: true,
            });
            this.workflowRequestList.sort(
              (a, b) =>
                new Date(b.transaction_createdAt) -
                new Date(a.transaction_createdAt)
            );
          }
        });
      });
      this.inboxCount = this.workflowRequestList.length;
    },

    async getSubWorkflowInboxList() {
      const { error, payload } = await workflow.getSentList(this.workflow.id, {
        filterBy: [],
        itemsPerPage: 100,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.totalItems = this.totalItems + payload.meta?.totalItems || 0;
      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let isFound = this.workflowRequestList.filter(
            (row) => row.processId === process.processId
          );
          if (isFound.length === 0) {
            let reqInbox = false;
            if (process.subworkflowTransaction) {
              if (process.subworkflowTransaction.length) {
                process.subworkflowTransaction.forEach((req) => {
                  if (req.activityUserId === this.$store.state.session.id) {
                    reqInbox = true;
                    return;
                  }
                });
              }
            }
            if (reqInbox) {
              let stepCount = 0;
              let workflowJson = JSON.parse(this.workflow.flowJson);
              let check = true;
              workflowJson.blocks.forEach((block) => {
                if (block.id !== process.activityId && check) {
                  stepCount += 1;
                } else {
                  if (process.flowStatus == 1) {
                    stepCount += 1;
                  }
                  check = false;
                }
              });
              this.workflowRequestList.push({
                ...process,
                step: stepCount,
                visible: true,
                expand: false,
                inbox: false,
              });
              this.workflowRequestList.sort(
                (a, b) =>
                  new Date(b.transaction_createdAt) -
                  new Date(a.transaction_createdAt)
              );
            }
          }
        });
      });
      this.inboxCount = this.workflowRequestList.length;
    },

    async refresh(id, action) {
      let data = [];
      if (action === "") {
        const { error, payload } = await workflow.getInboxList(
          this.workflow.id,
          {
            filterBy: [],
            itemsPerPage: 100,
            currentPage: 1,
          }
        );
        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.data.length) {
          data = payload.data;
        }
      } else {
        const { error, payload } = await workflow.getSentList(
          this.workflow.id,
          {
            filterBy: [],
            itemsPerPage: 100,
            currentPage: 1,
          }
        );
        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.data.length) {
          data = payload.data;
        }
      }

      if (data.length) {
        const isMatchFound = data[0].value.findIndex(
          (item) => item.requestNo === id
        );
        if (isMatchFound < 0) {
          await this.refresh(id, action);
        } else {
          this.getRequestList();
        }
      } else {
        await this.refresh(id, action);
      }
    },

    openNewRequest() {
      this.btnAction = false;
      this.selectedProcess = {};
      this.formModel = {};
      this.workflowModel = {};
      this.commentsList = [];
      this.attachmentList = [];
      this.actions = [];
      this.ocrResult = [];
      this.uploadAccess = true;
      this.tabs.find((tab) => {
        tab.count = 0;
      });
      let workflowJson = JSON.parse(this.workflow.flowJson);
      let startBlock = workflowJson.blocks.find(
        (block) => block.type === "START"
      );

      if (startBlock) {
        if (startBlock.settings.initiateBy.indexOf("PORTAL") > -1) {
          this.headerTitle = startBlock.settings.label;
          if (startBlock.settings.fileSettings) {
            if (startBlock.settings.fileSettings.fileCheckList.length) {
              this.checkList = startBlock.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
                this.fileUploadRequired = !this.fileUploadRequired;
              });
              this.docWarning = startBlock.settings.fileSettings.partial;
            }
          }
          let rules = workflowJson.rules.find(
            (rule) => rule.fromBlockId === startBlock.id
          );
          if (rules) {
            this.actions.push(rules.proceedAction);
          }
        }
        if (startBlock.settings.formEditAccess) {
          if (startBlock.settings.formEditAccess === "") {
            this.formEditAccess = true;
            this.formEditControls = [];
          } else if (startBlock.settings.formEditAccess === "FULL") {
            this.formEditAccess = false;
            this.formEditControls = [];
          } else if (startBlock.settings.formEditAccess === "PARTIAL") {
            this.formEditAccess = false;
            this.formEditControls = startBlock.settings.formEditControls;
          }
        }
        if (startBlock.settings.formVisibilityAccess) {
          this.formVisibilityAccess = true;
          this.formSecureControls = [];
        } else {
          this.formVisibilityAccess = false;
          this.formSecureControls = startBlock.settings.formSecureControls;
        }
        this.generatePDF = startBlock.settings.generatePDF ? 1 : 0;
      }
      this.selectedNode = "inbox";
      console.log(JSON.stringify(this.workflowModel));
      this.processSheet = true;
      if (this.portalMasterFormId) {
        this.getMasterFormData(this.portalMasterFormId);
      }
    },

    async openProcess(request) {
      this.btnAction = false;
      this.actions = [];
      this.selectedProcess = request;
      this.formModel = {};
      this.itemModel = {};
      this.formEditAccess = true;
      this.attachmentList = [];
      this.commentsList = [];
      this.ocrResult = [];
      this.formView = false;
      this.uploadAccess = false;
      if (this.selectedProcess.formData) {
        let keys = Object.keys(this.selectedProcess.formData.fields);
        let newValues = {};
        keys.forEach((key) => {
          newValues[key] = this.validateTypeValue(
            this.selectedProcess.formData.fields[key]
          );
        });
        this.formModel = newValues;
      }
      this.headerTitle = this.selectedProcess.requestNo;
      if (this.selectedProcess.inbox) {
        this.uploadAccess = true;
        this.formView = true;
        let workflowJson = JSON.parse(this.workflow.flowJson);
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );

        if (block) {
          if (block.settings.fileSettings) {
            if (block.settings.fileSettings.fileCheckList.length) {
              this.checkList = block.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
                this.fileUploadRequired = !this.fileUploadRequired;
              });
              //this.checkDocument();
              this.docWarning = block.settings.fileSettings.partial;
            }
          }
          workflowJson.rules.forEach((rule) => {
            if (rule.fromBlockId === this.selectedProcess.activityId) {
              if (this.actions.indexOf(rule.proceedAction) < 0) {
                this.actions.push(rule.proceedAction);
                this.processActionsAddOn.push({
                  action: rule.proceedAction,
                  remarks: rule.remarks || false,
                  passwordAccess: rule.passwordAccess || false,
                  confirm: rule.confirm || false,
                });
              }
            }
          });
          if (block.settings.formEditAccess) {
            if (block.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (block.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (block.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = block.settings.formEditControls;
            }
          }
          if (block.settings.formVisibilityAccess) {
            this.formVisibilityAccess = true;
            this.formSecureControls = [];
          } else {
            this.formVisibilityAccess = false;
            this.formSecureControls = block.settings.formSecureControls;
          }
          if (
            workflowJson.settings.general.processNumberPrefix.indexOf("[") > -1
          ) {
            let requestNumberFormat = JSON.parse(
              workflowJson.settings.general.processNumberPrefix
            );
            this.readonlyControls = [];
            let requestColumn = requestNumberFormat.filter(
              (row) => row.key === "formColumn"
            );
            if (requestColumn.length) {
              requestColumn.forEach((row) => {
                this.readonlyControls.push(row.value);
                this.formEditControls.splice(
                  this.formEditControls.indexOf(row.value),
                  1
                );
              });
            }
          }
          this.generatePDF = block.settings.generatePDF ? 1 : 0;
        }
      }

      if (this.actions.length) {
        this.selectedNode = "inbox";
      } else {
        this.selectedNode = "sent";
      }

      this.getComments();
      this.getAttachments();
      this.processSheet = true;
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    async getWorkflowData(filters) {
      this.$store.commit("showLoadingBar");
      this.workflowRequestList = [];
      const { error, payload } = await report.getWorkflowData(
        this.workflow.id,
        {
          filterBy: filters,
          itemsPerPage: 100,
          currentPage: 1,
          mode: "",
        }
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.totalItems = payload.meta?.totalItems || 0;
      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let stepCount = 0;
          let workflowJson = JSON.parse(this.workflow.flowJson);
          let check = true;
          workflowJson.blocks.forEach((block) => {
            if (block.id !== process.activityId && check) {
              stepCount += 1;
            } else {
              if (process.flowStatus == 1) {
                stepCount += 1;
              }
              check = false;
            }
          });
          this.workflowRequestList.push({
            ...process,
            step: stepCount,
            visible: true,
            expand: false,
            inbox: false,
          });
          this.workflowRequestList.sort(
            (a, b) =>
              new Date(b.transaction_createdAt) -
              new Date(a.transaction_createdAt)
          );
        });
      });
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }
      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value,
            value: String(user.id),
          });
        });
      }
    },

    documentAction(action) {
      if (action.id === "Email_Sharing") {
        //
      } else if (action.id === "Print") {
        if (this.selectedFile.name) {
          let ext = this.selectedFile.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.selectedFile.id);
          } else {
            this.$refs.myFiles.contentWindow.print();
          }
        }
        this.filePrintSession();
      } else if (action.id === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    closeSubProcess() {
      this.openSubProcessSheet = false;
      this.subProcessData = {};
      this.getRequestList();
    },

    mandatoryControlsList(value) {
      this.mandatory = value;
    },

    deletedFiles(ids, files) {
      this.fileControlDeleteFile.push(ids);

      if (files) {
        if (files.controls) {
          if (files.controls.length) {
            files.controls.forEach((field) => {
              this.workflowModel[field] = "";
            });
          }
        }

        this.ocrResult = [];

        let hasFile = this.attachmentList.findIndex((file) => {
          return (
            file.name === files.file.name &&
            file.id === files.file.fileId &&
            file.fileControl
          );
        });
        // console.log(hasFile);
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    checkFormValidation() {
      this.hasCheckFormValidate = true;
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async openSubProcess(request) {
      this.subWorkflowId = request.id;
      this.loadingBarContent = true;
      const { payload } = await report.getWorkflowData(this.subWorkflowId, {
        filterBy: [
          {
            filters: [
              {
                criteria: "requestNo",
                condition: "IS_EQUALS_TO",
                value: request.requestNo,
                valueTo: "",
                arrayValue: "",
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 10,
        currentPage: 1,
        mode: "",
      });
      this.loadingBarContent = false;
      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.subProcessData = data[0].value[0];
          this.subProcessData.inbox = request.inbox;
          this.openSubProcessSheet = true;
        }
      }
    },

    async uploadFilesFromForm(files, control, fieldId, fields, aiSettings) {
      this.formUploadFiles = [];
      const formData = new FormData();
      if (files.length) {
        //this.$store.commit("showLoadingBarSave");
        for (let file of files) {
          if (file.name && !file.error) {
            formData.append("repositoryId", this.workflow.repositoryId);
            formData.append("file", file);
            formData.append("filename", file.name);
            if (fields) {
              formData.append("fields", fields);
            } else {
              formData.append("fields", "");
            }

            if (!isEmpty(aiSettings)) {
              if (aiSettings.formControlValidate) {
                if (aiSettings.formControlValidate.optionsType === "MASTER") {
                  formData.append(
                    "masterFormId",
                    aiSettings.formControlValidate.masterFormId
                  );

                  formData.append(
                    "masterFormColumn",
                    aiSettings.formControlValidate.masterFormColumn
                  );
                }

                if (aiSettings.fileDataValidate.length) {
                  formData.append(
                    "isValidateFile",
                    aiSettings.fileDataValidate.includes("EXPIRY_DATE")
                  );
                }
              }
            }

            try {
              const response = await axios({
                url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
                method: "POST",
                data: formData,
                headers: {
                  Token: this.$store.state.identity.token,
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                  let uploadedPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // console.log(this.workflowModel, fieldId);
                  let hasFile = this.workflowModel[fieldId].findIndex(
                    (item) => {
                      return item.name === file.name;
                    }
                  );
                  // console.log(hasFile);
                  if (hasFile >= 0) {
                    this.$set(
                      this.workflowModel[fieldId][hasFile],
                      "uploadedPercentage",
                      0
                    );
                    if (uploadedPercentage > 50 && uploadedPercentage !== 100) {
                      this.workflowModel[fieldId][hasFile].uploadedPercentage =
                        uploadedPercentage;
                    } else {
                      this.workflowModel[fieldId][
                        hasFile
                      ].uploadedPercentage = 98;
                    }
                  }
                }.bind(this),
              });

              const { status, data } = response;
              if (status !== 200) {
                throw "Invalid response";
              }
              file.createdAt = this.$day.newDateTime();
              file.createdBy = this.$store.state.session.email;
              let filesList = JSON.parse(data);
              file.fileId = filesList.fileId;
              file.fieldId = fieldId;
              file.fileDataValidate = filesList.OCRfieldlist;
              this.attachmentList.push({
                id: Number(filesList.fileId),
                name: file.name,
                createdAt: new Date().toISOString(),
                createdByEmail: this.$store.state.session.email,
                size: file.size,
                initiate: true,
                formAttach: true,
                fileControl: true,
                fieldId: fieldId,
                control: control,
              });
              this.tabs.find((tab) => {
                if (tab.value === "ATTACHMENTS") {
                  tab.count = this.attachmentList.length;
                }
              });
            } catch (error) {
              console.error(error);
              this.$alert.error(`error attaching file`);
            }
          }
          let hasFile = this.workflowModel[fieldId].findIndex((item) => {
            return item.name === file.name;
          });

          // console.log(hasFile);

          if (hasFile >= 0) {
            this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
          }
        }

        let _files = Array.from(files);
        // console.log(_files);
        this.formUploadFiles = [];
        for (let file of _files) {
          // console.log(file.name);
          if (file.name) {
            const base64String = await fileToBase64(file);

            this.formUploadFiles.push({
              name: file.name,
              size: file.size,
              file: base64String,
              fileId: file.fileId,
              createdBy: file.createdBy,
              createdAt: file.createdAt,
              workflowId: this.selectedProcess.id,
              processId: this.selectedProcess.processId,
              fieldId: file.fieldId,
              fileDataValidate: file.fileDataValidate,
            });
          }
        }

        if (control) {
          if (control.length) {
            control.forEach((con) => {
              let index = this.readonlyControls.findIndex((item) => {
                return item === con;
              });

              // console.log(index);
              if (index >= 0) {
                this.readonlyControls.splice(index, 1);
              }
            });
          }
        }

        this.$store.commit("hideLoadingBarSave");
      }
    },

    async fileControlDeletedFile(file) {
      this.$store.commit("showLoadingBar");
      let fileIds = [];
      file.forEach((item) => {
        if (item.id) fileIds.push(item.id);
      });
      let input = {
        ids: fileIds,
        formInfo: {
          formId: this.formId,
          formEntryId: this.selectedProcess.formData.formEntryId,
          jsonId: file[0].jsonId,
        },
      };
      const { error } = await repository.fileControlDeleteFile(
        this.workflow.repositoryId,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getSubWorkflows(subWorkflowIds) {
      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "id",
                condition: "IS_EQUALS_TO",
                value: "[" + subWorkflowIds.toString() + "]",
                dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 0,
        currentPage: 0,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching workflows");
        return;
      }

      if (payload) {
        if (payload.data.length) {
          let subWorkflowFormIds = [];
          payload.data[0].value.forEach((workflow) => {
            subWorkflowFormIds.push(workflow.wFormId);
          });
          if (subWorkflowFormIds.length) {
            await this.getSubWorkflowForms(subWorkflowFormIds);
          }
        }
      }
    },

    async getSubWorkflowForms(subWorkflowFormIds) {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "id",
                condition: "IS_EQUALS_TO",
                value: "[" + subWorkflowFormIds.toString() + "]",
                dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 0,
        currentPage: 0,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching forms");
        return;
      }

      if (payload) {
        if (payload.data.length) {
          payload.data[0].value.forEach((form) => {
            this.subWorkflowFormList.push({
              id: form.id,
              name: form.name,
              formJson: form.formJson,
            });
          });
        }
      }
    },

    async uploadFileForControl(files, control, fieldId, aiSettings) {
      this.fileUploadInput = {
        files: files,
        control: control,
        fieldId: fieldId,
        aiSettings: aiSettings,
      };
      if (this.repositoryDetails.fieldsType === "STATIC") {
        let label = this.getLabel(fieldId);
        this.uploadFilename = files[0].name;

        //rename higher level name
        const highestLevelObject = this.repositoryField.reduce(
          (prev, curr) => (curr.level > prev.level ? curr : prev),
          this.repositoryField[0]
        );

        const highestLevelId = highestLevelObject.id;

        if (this.attachmentList.length) {
          let index = this.attachmentList.length - 1;
          await this.getFileData(this.attachmentList[index], "file");
        }

        const fileDataItem = this.fileData.find(
          (item) => highestLevelId == item.id
        );
        const newObjectToPush = {
          id: highestLevelObject.id,
          name: highestLevelObject.name,
          value: label,
          type: highestLevelObject.dataType,
        };
        if (!this.fileData.length) {
          this.fileData.push(newObjectToPush);
        } else {
          fileDataItem.value = label;
        }
        // console.log(this.attachmentList,"files, control, fieldId, aiSettings hasfiel")
        // console.log(label,"files, control, fieldId, aiSettings hasfiel")
        // console.log(this.fileData,"files, control, fieldId, aiSettings hasfiel")

        // check all mandatory filled
        const mandatoryItems = this.repositoryField
          .filter((item) => item.isMandatory === true)
          .map((item) => item.id);

        const allMandatoryFilled = mandatoryItems.every((mandatoryId) => {
          const fileItem = this.fileData.find(
            (item) => item.id === mandatoryId
          );
          return fileItem && fileItem.value !== "";
        });

        this.showMetaDataEditModal = !allMandatoryFilled;

        if (allMandatoryFilled) {
          const newObject = {};

          this.fileData.forEach((item) => {
            newObject[item.name] = item.value !== "" ? item.value : "";
          });

          this.fileUploadForControl = true;
          this.saveMetaData(newObject);
        } else {
          this.fileUploadForControl = true;
          this.showMetaDataEditModal = true;
        }
      } else {
        this.uploadFilesFromForm(files, control, fieldId, "", aiSettings);
      }
    },

    async getFileData(file, data) {
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });
      //this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      if (file.fileData) {
        this.fileData = file.fileData;
      } else {
        this.fileData = payload;
      }
      if (data !== "file") this.fileDetailsSheet = true;
    },

    async getOCRResult(files, control, fieldId, aiSettings) {
      const formData = new FormData();
      let formFields = [];
      if (control && control.length) {
        control.forEach((con) => {
          this.readonlyControls.push(con);
        });
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.value === field);
          if (name) {
            formFields.push(`${name.label}, ${name.type}`);
          }
        });
        if (files.length) {
          //this.$store.commit("showLoadingBarSave");
          for (let file of files) {
            if (file.name && !file.error) {
              formData.append("repositoryId", this.workflow.repositoryId);
              formData.append("file", file);
              if (formFields.length) {
                formData.append("formFields", JSON.stringify(formFields));
              }

              try {
                const response = await axios({
                  url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
                  method: "POST",
                  data: formData,
                  headers: {
                    Token: this.$store.state.identity.token,
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    let uploadedPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // console.log(this.workflowModel, fieldId);

                    let hasFile = this.workflowModel[fieldId].findIndex(
                      (item) => {
                        return item.name === file.name;
                      }
                    );
                    // console.log(hasFile);
                    if (hasFile >= 0) {
                      this.$set(
                        this.workflowModel[fieldId][hasFile],
                        "uploadedPercentage",
                        0
                      );
                      if (uploadedPercentage < 50) {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = uploadedPercentage;
                      } else {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = 49;
                      }
                    }
                  }.bind(this),
                });

                const { status, data } = response;
                if (status !== 200) {
                  throw "Invalid response";
                }

                let list = decrypt(
                  data,
                  this.$store.state.identity.key,
                  this.$store.state.identity.iv
                );

                let ocrList = JSON.parse(list);
                let ocr = JSON.parse(ocrList);
                let ocrFile = JSON.parse(ocr);

                if (Array.isArray(ocrFile)) {
                  ocrFile.forEach((hasFile) => {
                    let fieldId = this.formFields.find(
                      (item) => item.label === hasFile.name
                    );
                    if (fieldId) {
                      if (hasFile.value) {
                        this.workflowModel[fieldId.value] = hasFile.value;
                      }
                    }
                  });
                  this.ocrResult = ocrFile;
                }
              } catch (error) {
                console.error(error);
                this.$alert.error(`error on get ocr result`);
              }
            }
            let hasFile = this.workflowModel[fieldId].findIndex((item) => {
              return item.name === file.name;
            });

            // console.log(hasFile);

            if (hasFile >= 0) {
              //this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
            }
          }

          this.$store.commit("hideLoadingBarSave");
          this.uploadFileForControl(files, control, fieldId, aiSettings);
        }
      } else {
        this.uploadFileForControl(files, control, fieldId, aiSettings);
      }
    },

    async getMasterForm() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getForm(this.portalMasterFormId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.controllist.length) {
        this.masterFormFields = payload.controllist;
        this.masterFormJson = payload.formJson;
      }
    },

    async getMasterFormData(formId) {
      this.$store.commit("showLoadingBar");
      let filterCondition = [];
      if (typeof this.portalSettingJson.usernameField === "string") {
        filterCondition.push({
          filters: [
            {
              criteria: this.portalSettingJson.usernameField,
              condition: "IS_EQUALS_TO",
              value: this.$store.state.session.email,
              dataType: "SHORT_TEXT",
            },
          ],
          groupCondition: "",
        });
      } else {
        this.portalSettingJson.usernameField.forEach((field) => {
          filterCondition.push({
            filters: [
              {
                criteria: field,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: filterCondition.length > 0 ? "OR" : "",
          });
        });
      }

      const { error, payload } = await form.getFormEntries(formId, {
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: filterCondition,
        itemsPerPage: 10,
        currentPage: 1,
        portalId: this.portalId,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;

      if (data.length && data[0].value.length) {
        let values = data[0].value[0];
        let formJson = JSON.parse(this.masterFormJson);
        const panels = [...formJson.panels, ...formJson.secondaryPanels];
        if (!panels.length) {
          return;
        }
        let fields = [];
        for (const panel of panels) {
          fields.push(...panel.fields);
        }
        this.formFields.forEach((field) => {
          let hasFields = this.masterFormFields.find(
            (master) => lowerCase(master.name) === lowerCase(field.label)
          );
          if (hasFields) {
            if (hasFields.type !== "TABLE") {
              this.workflowModel[field.value] = values[hasFields.jsonId];
            } else {
              console.log(hasFields, values[hasFields.jsonId]);
              let tableField = fields.find(
                (item) => item.id === hasFields.jsonId
              );
              let tableValue = JSON.parse(values[hasFields.jsonId]);
              let mainColumns = field.settings.specific.tableColumns;

              let subColumns = tableField.settings.specific.tableColumns;
              console.log(tableValue, mainColumns, tableField, subColumns);

              let syncField = [];
              mainColumns.forEach((mColumn) => {
                let indexColumn = subColumns.findIndex((subColumn) => {
                  return mColumn.label === subColumn.label;
                });
                // console.log(indexColumn, "indexColumn");
                if (indexColumn > -1) {
                  syncField.push({
                    id: this.$nano.id(),
                    mainColumn: mColumn.id,
                    subColumn: subColumns[indexColumn].id,
                  });
                }
              });
              console.log(syncField, "syncField");
              let rows = [];
              tableValue.forEach((row) => {
                let tableRow = {};
                for (let key in row) {
                  let hasName = syncField.find(
                    (item) => item.subColumn === key
                  );
                  if (hasName) {
                    this.$set(
                      tableRow,
                      [hasName.mainColumn],
                      row[hasName.subColumn]
                    );
                  } else {
                    let type = mainColumns.find((col) => col.id === key);
                    if (type.type === "FILE_UPLOAD") {
                      this.$set(tableRow, key, []);
                    } else {
                      this.$set(tableRow, key, "");
                    }
                  }
                }
                if (tableRow) {
                  rows.push(tableRow);
                }
              });
              console.log(rows);
              this.workflowModel[field.value] = rows;
              this.$set(this.hasChangeTableField, field.value, rows);
            }
          }
        });
      }
    },

    attachmentCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = count;
        }
      });
      let files = [];

      this.mandatoryDocument.forEach((file) => {
        if (!file.attach) {
          files.push(file);
        }
      });

      this.requiredDocuments = [];
      this.requiredDocuments = files;
      if (this.selectedProcess.processId) {
        let process = this.workflowRequestList.find(
          (row) => row.processId === this.selectedProcess.processId
        );
        if (process) {
          process.attachmentCount = count;
        }
      }
    },

    commentsCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = count;
        }
      });
      if (this.selectedProcess.processId) {
        let process = this.workflowRequestList.find(
          (row) => row.processId === this.selectedProcess.processId
        );
        if (process) {
          process.commentsCount = count;
        }
      }
    },

    getColumns() {
      this.formFields = [];
      let origin = location.origin;
      this.columns = [
        {
          id: "requestNo",
          name: "requestNo",
          label: "Request No",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      if (this.isTenantGOTO()) {
        this.columns.push({
          id: "mlCondition",
          name: "mlCondition",
          label: "",
          type: "MLCONDITION",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
        });
      }
      if (
        this.isTenantArmgroup() ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        this.columns.push({
          id: "mlCondition",
          name: "mlCondition",
          label: "",
          type: "MLCONDITION",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
        });
      }
      let defaultColumns = [
        {
          id: "stage",
          name: "stage",
          label: "Stage",
          type: "NORMAL",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: "actionUser",
          name: "actionUser",
          label: "Action User",
          type: "NORMAL",
          options: this.userList,
          isVisible: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: "raisedBy",
          name: "raisedBy",
          label: "Raised By",
          type: "NORMAL",
          options: this.userList,
          isVisible: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: "raisedAt",
          name: "raisedAt",
          label: "Raised On",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          dataType: DataType.DATE,
        },
        {
          id: "lastAction",
          name: "lastAction",
          label: "Last Action",
          type: "NORMAL",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: "lastActionBy",
          name: "lastActionBy",
          label: "Last Action By",
          type: "NORMAL",
          options: this.userList,
          isVisible: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: "lastActionOn",
          name: "lastActionOn",
          label: "Last Action On",
          type: "NORMAL",
          isVisible: true,
          dataType: DataType.DATE,
        },
        {
          id: "duration",
          name: "duration",
          label: "Duration",
          type: "NORMAL",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      if (
        this.isTenantArmgroup() ||
        origin === "http://localhost:8080" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        if (this.workflow.wFormId && this.wform.formJson) {
          let formJson = JSON.parse(this.wform.formJson);
          const panels = [...formJson.panels, ...formJson.secondaryPanels];

          if (!panels.length) {
            return;
          }
          let fields = [];
          for (const panel of panels) {
            fields.push(...panel.fields);
          }
          let initialsField = fields.find((row) => row.label === "Initials");
          if (initialsField) {
            this.columns.push({
              id: initialsField.id,
              name: initialsField.id,
              label: initialsField.label,
              type: "NORMAL",
              isVisible: true,
              dataType: initialsField.type,
              apiLoad: true,
              isFilter: true,
            });
          }
          let benefitClaimField = fields.find(
            (row) => row.label === "Benefit Claimed"
          );
          if (benefitClaimField) {
            this.columns.push({
              id: benefitClaimField.id,
              name: benefitClaimField.id,
              label: benefitClaimField.label,
              type: "NORMAL",
              isVisible: true,
              dataType: benefitClaimField.type,
              apiLoad: true,
              isFilter: true,
            });
          }
        }
        this.columns.push(
          {
            id: "stage",
            name: "stage",
            label: "Stage",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.SHORT_TEXT,
          },
          {
            id: "actionUser",
            name: "actionUser",
            label: "Action User",
            type: "NORMAL",
            options: this.userList,
            isVisible: true,
            dataType: DataType.SINGLE_SELECT,
          },

          {
            id: "lastAction",
            name: "lastAction",
            label: "Last Action",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.SHORT_TEXT,
          },
          {
            id: "lastActionBy",
            name: "lastActionBy",
            label: "Last Action By",
            type: "NORMAL",
            options: this.userList,
            isVisible: true,
            dataType: DataType.SINGLE_SELECT,
          },
          {
            id: "lastActionOn",
            name: "lastActionOn",
            label: "Last Action On",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.DATE,
          },
          {
            id: "duration",
            name: "duration",
            label: "Duration",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.SHORT_TEXT,
          },
          {
            id: "raisedBy",
            name: "raisedBy",
            label: "Raised By",
            type: "NORMAL",
            options: this.userList,
            isVisible: true,
            dataType: DataType.SINGLE_SELECT,
          },
          {
            id: "raisedAt",
            name: "raisedAt",
            label: "Raised On",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.DATE,
          }
        );
      } else {
        this.columns.push(...defaultColumns);
      }
      if (this.workflow.wFormId && this.wform.formJson) {
        let formJson = JSON.parse(this.wform.formJson);
        const panels = [...formJson.panels, ...formJson.secondaryPanels];

        if (!panels.length) {
          return;
        }
        let fields = [];
        for (const panel of panels) {
          fields.push(...panel.fields);
        }

        let wflowJson = JSON.parse(this.workflow.flowJson);
        let secureControls = [];
        const controls = wflowJson.blocks.reduce((acc, control) => {
          if (
            control.settings.formSecureControls &&
            control.settings.formSecureControls.length
          ) {
            if (typeof control.settings.formSecureControls[0] === "string") {
              control.settings.users.forEach((user) => {
                acc.push({ [user]: control.settings.formSecureControls });
              });
            } else {
              control.settings.formSecureControls.forEach((user) => {
                if (user.formFields.length) {
                  acc.push({ [user.userId]: user.formFields });
                }
              });
            }
          }
          return acc;
        }, []);
        controls.forEach((user) => {
          if (user[this.$store.state.session.id]) {
            secureControls.push(...user[this.$store.state.session.id]);
          }
        });

        fields.forEach((field) => {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            (field.label || field.type === "TABLE") &&
            field.settings.general.visibility !== "DISABLE"
          ) {
            if (secureControls.length) {
              if (typeof secureControls[0] === "object") {
                let user = secureControls.findIndex(
                  (item) => item.userId === this.$store.state.session.id
                );
                if (user >= 0) {
                  let controls = secureControls[user].formFields;
                  let index = controls.findIndex((item) => item === field.id);
                  if (index === -1) {
                    if (!this.myInbox) {
                      this.columns.push({
                        id: field.id,
                        name: field.id,
                        label: field.label,
                        type: "NORMAL",
                        isVisible: true,
                        dataType: field.type,
                        apiLoad: true,
                        isFilter: true,
                      });
                    }

                    this.formFields.push({
                      id: this.$nano.id(),
                      label: field.label || field.type,
                      value: field.id,
                      type: field.type,
                      settings: field.settings,
                    });
                  }
                }
              } else {
                let index = secureControls.findIndex(
                  (item) => item === field.id
                );
                if (index === -1) {
                  if (!this.myInbox) {
                    this.columns.push({
                      id: field.id,
                      name: field.id,
                      label: field.label,
                      type: "NORMAL",
                      isVisible: true,
                      dataType: field.type,
                      apiLoad: true,
                      isFilter: true,
                    });
                  }

                  this.formFields.push({
                    id: this.$nano.id(),
                    label: field.label || field.type,
                    value: field.id,
                    type: field.type,
                    settings: field.settings,
                  });
                }
              }
            } else {
              if (!this.myInbox) {
                let index = this.columns.findIndex(
                  (item) => item.id === field.id
                );
                if (index === -1) {
                  this.columns.push({
                    id: field.id,
                    name: field.id,
                    label: field.label,
                    type: "NORMAL",
                    isVisible: true,
                    dataType: field.type,
                    apiLoad: true,
                    isFilter: true,
                  });
                }
              }

              this.formFields.push({
                id: this.$nano.id(),
                label: field.label || field.type,
                value: field.id,
                type: field.type,
                settings: field.settings,
              });
            }
          }
        });
      } else if (this.workflow.repositoryId && this.repository.fields) {
        this.repository.fields.forEach((field) => {
          if (field.name && field.dataType !== "TABLE") {
            if (!this.myInbox) {
              this.columns.push({
                id: field.id,
                name: field.name,
                label: field.name,
                type: "NORMAL",
                isVisible: true,
                dataType: field.dataType,
              });
            }
          }
        });
      }
    },

    async getOCRResultInTable(values) {
      console.log(values);
      let files = values.files.files;
      // let aiSettings = values.files.aiSettings;
      let fieldId = values.files.fieldId;
      let control = values.files.assignControls;
      let rowIndex = values.rowIndex;
      let tableId = values.tableId;
      // let fields = "";
      let formFields = [];
      if (control && control.length) {
        // control.forEach((con) => {
        //   this.readonlyControls.push(con);
        // });
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.value === tableId);
          if (name) {
            let columns = name.settings.specific.tableColumns;
            let hasColumn = columns.find((item) => item.id === field);
            if (hasColumn) {
              formFields.push(`${hasColumn.label}, ${hasColumn.type}`);
            }
          }
        });
      }

      if (control && control.length) {
        if (files.length) {
          //this.$store.commit("showLoadingBarSave");
          for (let file of files) {
            const formData = new FormData();
            if (file.name && !file.error) {
              formData.append("repositoryId", this.workflow.repositoryId);
              formData.append("file", file);
              if (formFields.length) {
                formData.append("formFields", JSON.stringify(formFields));
              }

              let index = this.workflowModel[tableId][rowIndex][
                fieldId
              ].findIndex((item) => {
                return item.name === file.name;
              });
              console.log(index, "index");

              try {
                const response = await axios({
                  url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
                  method: "POST",
                  data: formData,
                  headers: {
                    Token: this.$store.state.identity.token,
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    let uploadedPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // console.log(this.workflowModel, fieldId);

                    if (uploadedPercentage !== 100) {
                      this.$set(
                        this.workflowModel[tableId][rowIndex][fieldId][index],
                        "uploadedPercentage",
                        uploadedPercentage
                      );
                    }
                  }.bind(this),
                });

                const { status, data } = response;
                if (status !== 200) {
                  throw "Invalid response";
                }

                let list = decrypt(
                  data,
                  this.$store.state.identity.key,
                  this.$store.state.identity.iv
                );

                console.log(list, "list");

                let ocrList = JSON.parse(list);
                let ocr = JSON.parse(ocrList);
                let ocrFile = JSON.parse(ocr);
                console.log(ocrFile, "ocrFile");

                if (Array.isArray(ocrFile)) {
                  ocrFile.forEach((hasFile) => {
                    let table = this.formFields.find(
                      (item) => item.value === tableId
                    );
                    if (table) {
                      let columns = table.settings.specific.tableColumns;
                      columns.forEach((col) => {
                        if (hasFile.name === col.label) {
                          if (hasFile.value) {
                            if (
                              col.id in this.workflowModel[tableId][rowIndex]
                            ) {
                              this.workflowModel[tableId][rowIndex][col.id] =
                                hasFile.value;
                            } else {
                              this.$set(
                                this.workflowModel[tableId][rowIndex],
                                col.id,
                                hasFile.value
                              );
                            }

                            console.log(
                              this.workflowModel[tableId][rowIndex],
                              "ocr1File"
                            );
                            // this.workflowModel[col.id] = hasFile.value;
                          }
                        }
                      });
                    }
                  });
                  // this.ocrResult = ocrFile;
                }
              } catch (error) {
                console.error(error);
                this.$alert.error(`error on get ocr result in table`);
              }
            }
          }

          this.$store.commit("hideLoadingBarSave");
          this.uploadFilesInTable(values);
        }
      } else {
        this.uploadFilesInTable(values);
      }
    },

    async uploadFilesInTable(values) {
      console.log(values);
      let files = values.files.files;
      let aiSettings = values.files.aiSettings;
      let fieldId = values.files.fieldId;
      let control = values.files.assignControls;
      let rowIndex = values.rowIndex;
      let tableId = values.tableId;
      let fields = "";

      // this.formUploadFiles = [];

      if (files.length) {
        //this.$store.commit("showLoadingBarSave");
        for (let file of files) {
          const formData = new FormData();
          if (file.name && !file.error) {
            formData.append("repositoryId", this.workflow.repositoryId);
            formData.append("file", file);
            formData.append("filename", file.name);
            if (fields) {
              formData.append("fields", fields);
            } else {
              formData.append("fields", "");
            }

            if (!isEmpty(aiSettings)) {
              if (aiSettings.formControlValidate) {
                if (aiSettings.formControlValidate.optionsType === "MASTER") {
                  formData.append(
                    "masterFormId",
                    aiSettings.formControlValidate.masterFormId
                  );

                  formData.append(
                    "masterFormColumn",
                    aiSettings.formControlValidate.masterFormColumn
                  );
                }

                if (aiSettings.fileDataValidate.length) {
                  formData.append(
                    "isValidateFile",
                    aiSettings.fileDataValidate.includes("EXPIRY_DATE")
                  );
                }
              }
            }
            let index = this.workflowModel[tableId][rowIndex][
              fieldId
            ].findIndex((item) => {
              return item.name === file.name;
            });
            console.log(index, "index");

            try {
              const response = await axios({
                url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
                method: "POST",
                data: formData,
                headers: {
                  Token: this.$store.state.identity.token,
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                  let uploadedPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // console.log(uploadedPercentage, fieldId);
                  if (uploadedPercentage !== 100) {
                    this.$set(
                      this.workflowModel[tableId][rowIndex][fieldId][index],
                      "uploadedPercentage",
                      uploadedPercentage
                    );
                  }
                }.bind(this),
              });

              const { status, data } = response;
              if (status !== 200) {
                throw "Invalid response";
              }
              console.log(data);
              this.$set(
                this.workflowModel[tableId][rowIndex][fieldId][index],
                "uploadedPercentage",
                100
              );

              let filesList = JSON.parse(data);
              file.fileId = filesList.fileId;
              file.fieldId = fieldId;
              file.fileDataValidate = filesList.OCRfieldlist;
              this.$set(
                this.workflowModel[tableId][rowIndex][fieldId][index],
                "fileId",
                Number(filesList.fileId)
              );
              this.$set(
                this.workflowModel[tableId][rowIndex][fieldId][index],
                "createdBy",
                this.$store.state.session.email
              );
              this.$set(
                this.workflowModel[tableId][rowIndex][fieldId][index],
                "createdAt",
                this.$day.newDateTime()
              );

              this.workflowModel[tableId][rowIndex][fieldId][
                index
              ].fileDataValidate = filesList.OCRfieldlist;

              console.log(this.workflowModel[tableId][rowIndex][fieldId]);
              this.attachmentList.push({
                id: Number(filesList.fileId),
                name: file.name,
                createdAt: new Date().toISOString(),
                createdByEmail: this.$store.state.session.email,
                size: file.size,
                initiate: true,
                formAttach: true,
                fileControl: true,
                fieldId: fieldId,
                control: control,
                tableControl: true,
                tableId: tableId,
                rowIndex: rowIndex + 1,
              });
              this.tabs.find((tab) => {
                if (tab.value === "ATTACHMENTS") {
                  tab.count = this.attachmentList.length;
                }
              });
            } catch (error) {
              console.error(error);
              this.$alert.error(`error attaching file`);
            }
          }
          // let hasFile = this.workflowModel[fieldId].findIndex((item) => {
          //   return item.name === file.name;
          // });

          // // console.log(hasFile);

          // if (hasFile >= 0) {
          //   this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
          // }
        }

        // let _files = Array.from(files);
        // // console.log(_files);
        // this.formUploadFiles = [];
        // for (let file of _files) {
        //   // console.log(file.name);
        //   if (file.name) {
        //     const base64String = await fileToBase64(file);

        //     this.formUploadFiles.push({
        //       name: file.name,
        //       size: file.size,
        //       file: base64String,
        //       fileId: file.fileId,
        //       createdBy: file.createdBy,
        //       createdAt: file.createdAt,
        //       workflowId: this.selectedProcess.id,
        //       processId: this.selectedProcess.processId,
        //       fieldId: file.fieldId,
        //       fileDataValidate: file.fileDataValidate,
        //     });
        //   }
        // }

        // if (control) {
        //   if (control.length) {
        //     control.forEach((con) => {
        //       let index = this.readonlyControls.findIndex((item) => {
        //         return item === con;
        //       });

        //       // console.log(index);
        //       if (index >= 0) {
        //         this.readonlyControls.splice(index, 1);
        //       }
        //     });
        //   }
        // }

        this.$store.commit("hideLoadingBarSave");
      }
    },

    deletedFilesInTable(value) {
      console.log(value);

      value.fileIds.forEach((file) => {
        this.fileControlDeleteFile.push(file);
      });

      let initiateFiles = value.fileIds.filter((file) => file.initiate);

      initiateFiles.forEach((item) => {
        let hasFile = this.attachmentList.findIndex((file) => {
          return file.id === item.id && file.tableControl;
        });
        console.log(hasFile);
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      });
    },

    viewFile(file, item) {
      console.log(file, item);
      if (item) {
        file.createdBy = item.createdBy;
        file.createdAt = item.createdAt;
      }
      if (!file.name) {
        file.name = file.fileName;
      }

      if (!file.id) {
        if (file.itemId) {
          file.id = file.itemId;
        } else {
          this.$set(file, "id", file.fileId);
          console.log(file, item);
        }
      }
      if (file.initiate) {
        this.$set(file, "initiate", true);
      }
      this.openFile(file);
    },
  },
};
</script>
<style lang="scss" scoped>
.theme-light {
  .list-item {
    background-color: rgba(0, 188, 212, 0.15);
  }

  #portals-access .list {
    background-color: #e1f5f9;
    &:hover {
      background-color: $secondary-2 !important;
    }
  }
}

.theme-dark {
  .list-item {
    background-color: rgba(38, 197, 218, 0.15);
  }

  #portals-access .list {
    &:hover {
      background-color: var(--secondary) !important;
    }
  }

  .menuDescription .text-caption {
    color: white !important;
  }

  #requestDetails {
    table {
      &.submittedData {
        background: #510763 !important;
      }
    }
  }
}
#portals-access {
  margin: auto;
  padding: 16px;

  .logo img {
    // height: 24px;
    width: auto;
  }

  .welcomeHeader {
    padding: 16px;
    border-radius: 4px;
    background-color: #8c52ff;
    margin-bottom: 16px;
    align-items: center;
    color: #fff;

    .welcomeTextHigh {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 24px !important;
    }

    .welcomeTextSmall {
      font-size: 20px;
      margin-bottom: 10px !important;
    }
  }

  .portal-header {
    border-radius: 4px;
    border-top: 4px solid var(--primary);
  }

  .logo {
    background-color: var(--component-bg-color);
    margin-bottom: 16px;
    // img {
    //   height: 65px;
    // }
  }

  .header {
    padding: 16px;
    // border-radius: 4px;
    background-color: var(--component-bg-color);
    // border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: right;
    justify-content: right;
    position: fixed;
    right: 15px;
    bottom: 12px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }

  .content {
    border-radius: 4px;
    background-color: var(--component-bg-color);
    height: calc(100vh - 125px);

    .mainMenu {
      padding: 15px;
      border: 1px solid var(--secondary);
      border-radius: 4px;

      .title {
        font-size: 16px;
      }

      .list-item {
        height: 36px;
      }

      .medium {
        font-size: 14px;
      }

      .small {
        font-size: 12px;
      }

      &:hover {
        background-color: $secondary-1;
      }
    }

    .subMenu:hover {
      background-color: $secondary-1;
    }

    .list-item {
      height: 48px;
      border-radius: 4px;
      padding: 16px;
      font-weight: 500;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      user-select: none;
      background-color: rgba(0, 188, 212, 0.15);

      &:hover {
        cursor: pointer;
        color: white;
        background-color: var(--secondary);
      }
    }

    .menuDescription {
      border: 1px solid var(--divider-color);

      .medium {
        height: 160px;
        .justify-center {
          justify-content: normal;
        }
      }
    }

    .menuAction .action {
      margin: auto;
    }

    .subcontent,
    .subdetcontent {
      border-radius: 10px;
      border: 1px solid var(--divider-color);

      .alert {
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        border-radius: 9px;
        font-size: 12px;
        padding: 0 5px;
        vertical-align: top;
        height: 18px;
        margin-top: -20px;
        margin-right: -20px;
        position: absolute;
        z-index: 1;
      }
    }

    .subdetcontent {
      #action-btn.tabButtons {
        height: 52px;
        width: 52px;
      }

      .badge {
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        border-radius: 9px;
        font-size: 12px;
        background: $primary;
        color: #fff;
        padding: 0 5px;
        vertical-align: top;
        height: 18px;
        margin-top: -20px;
        margin-right: -20px;
        position: absolute;
        z-index: 1;
      }

      .list {
        border-radius: 6px;
        height: 100%;
        border: 1px solid #e1f5f9;
        cursor: pointer;
        font-size: 12px;

        &.active-list {
          background-color: $secondary-2 !important;
        }
      }

      .forms {
        border-radius: 6px;
        height: 100%;
        border: 1px solid #eee;
        padding: 5px;
        text-align: center;
      }

      .lblheader {
        text-align: center;
        color: $primary-11;
        font-weight: 500;
      }

      table {
        table-layout: fixed;
        // width: 100%;
        border-collapse: collapse;
        white-space: nowrap;

        tr {
          height: 36px;

          th {
            color: $primary-11;
            text-align: left;
          }
        }

        th,
        td {
          border: 1px solid var(--divider-color);
          padding: 8px;
          font-weight: 500;
        }

        .tblHeader {
          color: $primary-11;
          font-weight: 500;
          &.small {
            width: 200px;
          }
        }
      }

      .ellipsis:hover {
        overflow: visible;
        white-space: normal;
        word-break: break-all;
      }
    }
  }

  #global-search {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0px 12px;
    color: var(--icon-color);
    border: 1px solid $secondary;
    // background-color: var(--body-bg-color);

    .label {
      @extend .text-sm;
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
}

#sheet .defaultBottom {
  position: absolute;
  bottom: 0;
}

#sheet .attachmentList,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }
}

#modal #processHistory {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet {
  #form-view {
    background-color: var(--body-bg-color);
    margin: auto;
    padding: 16px;
  }
}

#sheet .leftBorder {
  border-left: 1px solid var(--divider-color);
}

#sheet #action-btn.tabButtons {
  height: 52px;
  width: 52px;
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}

#pagination.portal {
  padding-top: 5px !important;
  border-top: none;
}

.chip-warning {
  font-weight: 500;
  background-color: var(--component-bg-color-inferior);
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chip-success {
  font-weight: 500;
  background-color: #9bf3b0;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.attachment-label {
  border: 1px solid;
  border-radius: 20px;
  padding: 5px 10px;
  color: $secondary;
  background: $secondary-1;
  font-size: 12px;
  &.ellipsis:hover {
    white-space: normal;
  }
}

.toggle-btn {
  border: 1px solid var(--body-bg-color);
  border-radius: 10px;
  cursor: pointer;
  color: var(--body-text-color);
  background-color: var(--body-bg-color);
  font-weight: 500;
}

.active-btn {
  border: 1px solid var(--component-bg-color);
  background-color: var(--component-bg-color);
  border-radius: 10px;
}

.data-count {
  min-width: 18px;
  padding: 2px;
  position: absolute;
  text-align: center;
  border-radius: 22px;
  font-size: 10px;
  background: $secondary;
  color: #fff;
  padding: 0 4px;
  vertical-align: top;
  margin-top: -8px;
  /* margin-right: -12px; */
  position: absolute;
  z-index: 1;
}
/* .custom-tab-container{
  height:100%;
} */
/* Dark Mode Styles */
.custom-tab.active-btn {
  background-color: #333; /* Active tab background */
  color: #fff; /* Active tab text */
}

/* Light Mode Styles */
.custom-tab.active.light-mode {
  background-color: #e0e0e0; /* Light Mode Active background */
  color: #000; /* Light Mode Active text */
  outline: 2px solid #007bff; /* Active tab outline */
}

.custom-tab.light-mode:not(.active) {
  background-color: #f5f5f5; /* Light Mode Inactive background */
  color: #555; /* Light Mode Inactive text */
}
</style>
